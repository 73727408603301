import React, { useState } from "react";
import AdminProductCard from "../component/AdminProductCard";
import AddProduct from "./AddProduct/AddProduct";
import {
  useAllCategoriesWithItems,
  useDeleteCategory,
} from "../features/category/category-service";
import { Spin, Empty, Alert, Popconfirm, message, Form } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useImageUrl } from "../hooks/useImage";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CategoryForm from "./AddProduct/components/CategoryForm";
import { NextArrow, PrevArrow } from "../utils";
const Menu = () => {
  const [isShow, setIsShow] = useState(false);
  const [isShowEdit, setIsShowEdit] = useState(false);
  const [categoryData, setCategoryData] = useState();
  const [form] = Form.useForm();

  // Fetch categories with their items
  const {
    data: categoriesData,
    isLoading,
    isError,
    error,
  } = useAllCategoriesWithItems();

  // Add delete mutation hook
  const deleteCategory = useDeleteCategory();

  const handleCardClick = (id) => {
    console.log(`Category with ID: ${id} clicked!`);
  };

  const handleEdit = (data) => {
    const category = data.categoryData;
    console.log("category data handleedit===>", category);

    // Prefill the form
    form.setFieldsValue({
      title: category.name || "",
      description: "",
      mainImage: category.imageIds?.[0]
        ? [
            {
              uid: "-1",
              name: "mainImage.png",
              status: "done",
              url: `/path/to/images/${category.imageIds[0]}`, // Replace with your image base URL
            },
          ]
        : [],
    });
    console.log("Data======>", form);
    setCategoryData(data);
    setIsShowEdit(true);
  };

  const handleDelete = async (categoryId) => {
    try {
      await deleteCategory.mutateAsync(categoryId);
      message.success("Category deleted successfully");
    } catch (error) {
      message.error(
        "Failed to delete category: " +
          (error.response?.data?.details || error.message)
      );
    }
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(categoriesData?.categories.length, 4),
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const CategoryCard = ({ category }) => {
    const imageUrl = useImageUrl(category.categoryData.imageIds?.[0]);

    console.log(imageUrl);

    return (
      <div className="relative group">
        <AdminProductCard
          id={category.id}
          imageSrc={imageUrl}
          title={category.categoryData.name}
          description={category.categoryData.description}
          itemCount={category.items?.length || 0}
          onClick={() => handleCardClick(category.id)}
        />

        {/* Action buttons */}
        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-2">
          <button
            onClick={() => handleEdit(category)}
            className="w-8 h-8 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
          >
            <EditOutlined />
          </button>

          <Popconfirm
            title="Delete category"
            description="Are you sure you want to delete this category?"
            onConfirm={() => handleDelete(category.id)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ danger: true }}
          >
            <button className="w-8 h-8 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors">
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      </div>
    );
  };

  // Loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[60vh]">
        <Spin size="large" tip="Loading categories..." />
      </div>
    );
  }

  // Error state
  if (isError) {
    return (
      <Alert
        type="error"
        message="Error loading categories"
        description={error?.message || "Please try again later"}
        className="mt-5"
      />
    );
  }

  console.log(categoriesData, "categoriesData");

  return (
    <div className="mt-5">
      <div className="flex justify-between mt-6 border-b-4 border-primary p-2">
        <h3 className="text-[34px]">Menu</h3>
        <div>
          <button
            onClick={() => setIsShow(true)}
            className="bg-[#eab43e] h-[54px] rounded-[30px] p-2 text-white hover:bg-[#d9a32d] transition-colors"
          >
            Add Product/Category
          </button>
        </div>
      </div>

      {!isShow ? (
        <div className="mt-10">
          <h1 className="text-[24px] border-b-2 border-primary w-[20%] mb-6">
            Our Categories
          </h1>

          {!categoriesData?.categories?.length ? (
            <Empty description="No categories available" className="my-8" />
          ) : (
            // grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4
            // <div className="flex gap-4 mt-6">
            //   {categoriesData.categories.map((category) => (
            //     <Slider {...settings}>
            //       <CategoryCard key={category.id} category={category} />
            //     </Slider>
            //   ))}
            // </div>
            <div className="mt-6 slider-container w-full max-w-[1520px]">
              <Slider {...settings}>
                {categoriesData.categories.map((category) => (
                  <div key={category.id} className="px-2">
                    {" "}
                    {/* Add padding or spacing */}
                    <CategoryCard category={category} />
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      ) : (
        <AddProduct onClose={() => setIsShow(false)} />
      )}

      {isShowEdit && (
        <CategoryForm
          isShowEdit={isShowEdit}
          setIsShowEdit={setIsShowEdit}
          categoryData={categoryData}
        />
      )}
    </div>
  );
};

export default Menu;
