import React, { useEffect, useState } from 'react'
import TableComponent from '../component/Table'
import { useALLCustomer } from '../features/customers/customer-service'
import EntriesDropdown from '../component/DropDown';

const Customers = () => {
  const {data: customers, isLoading, isError} = useALLCustomer()
  console.log('customers', customers?.data?.customers)
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);

  const rowsPerPage = 10;

  useEffect(() => {
    if (customers?.data?.customers) {
      setTableData(customers.data.customers);
    }
  }, [customers]);
  
  const totalCustomers = tableData.length;

console.log(tableData)
  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = tableData.slice(startIndex, startIndex + rowsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };




  return (
    <div className='border-2 border-zinc-300 mt-10 rounded-3xl shadow-lg p-4'>
    <div className="flex justify-between mt-5 ">
      <EntriesDropdown />
      <div>
        Search: <input className="h-[40px] rounded-md" type="Search" />
      </div>
    </div>
    <table className="mt-8 w-[100%] ">
      <thead>
        <tr className="h-[55px] bg-[#000000CC] text-white">
          <th scope="col" className="rounded-tl-[10px] ">
            ID #
          </th>
          <th scope="col">Email</th>
          <th scope="col" className="rounded-tr-[10px] ">User Name</th>
    
        </tr>
      </thead>
      <tbody>
        {currentData.map((row, index) => (
          <tr className="border border-zinc-300 h-[60px]" key={index}>
            <th scope="row">{index + 1}</th>
    
            <td className="text-center">{row.emailAddress}</td>
            <td className="text-center">{row.givenName}</td>
          
         
          </tr>
        ))}
      </tbody>
    </table>
    <div className="flex items-center justify-between mt-4 p-1">
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <div className="flex gap-3">
        <button
          onClick={handlePrevPage}
          className="px-4 py-2 bg-gray-200 rounded-md bg-black text-white"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <div className="h-[40px] w-[40px] py-[8px] px-[16px] bg-[#eab43e] text-white rounded-md ">
          <span>{totalCustomers}</span>
        </div>
        <button
          onClick={handleNextPage}
          className="px-4 py-2 bg-gray-200 rounded-md bg-black text-white "
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  </div>
  )
}

export default Customers