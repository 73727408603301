import {
  MdBarChart,
  MdOutlineCreditCard,
  MdAutoFixHigh,
  MdGridView,
  MdHealthAndSafety,
  MdOutlineAirplanemodeActive,
} from "react-icons/md";

export const menuItems = [
  {
    menuLabel: "dashboard",
    icon: MdGridView,
    link: "main",
  },
  {
    menuLabel: "orders",
    icon: MdBarChart,
    link: "orders",
  },
  {
    menuLabel: "customers",
    icon: MdOutlineCreditCard,
    link: "customers",
  },

  {
    menuLabel: "menu",
    icon: MdAutoFixHigh,
    link: "menu",
  },
  {
    menuLabel: "complaints",
    icon: MdHealthAndSafety,
    link: "complaints",
  },
  {
    menuLabel: "sales report",
    icon: MdOutlineAirplanemodeActive,
    link: "sales-report",
  },
];
