import React, { useRef, useState, useEffect, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../component/Footer";
import ReviewsCart from "../component/ReviewsCart";
import LocationContainer from "../component/Location";
import { useAllCategoriesWithItems } from "../features/category/category-service";
import { useAllProducts } from "../features/products/products-service";
import MenuNav from "../component/MenuNav";
import ProductCard from "../component/ProductCard";

const LoadingSpinner = () => (
  <div className="min-h-screen flex flex-col items-center justify-center bg-white">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary mb-4"></div>
    <p className="text-gray-600 text-lg">Loading amazing dishes for you...</p>
  </div>
);

const HomePage = () => {
  const [navPosition, setNavPosition] = useState("top-[600px]");
  const sectionRefs = useRef([]);
  const navRef = useRef(null);
  
  // Get categories and products with loading and error states
  const { 
    data: category, 
    isLoading: isCategoryLoading, 
    error: categoryError 
  } = useAllCategoriesWithItems();
  
  const { 
    data: products, 
    isLoading: isProductsLoading, 
    error: productsError 
  } = useAllProducts();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Modify the product mapping section to include lazy loading
  const renderProducts = (categoryItem) => {
    const filteredProducts = products.products
      ?.filter((product) => {
        const categories = product?.itemData?.categories;
        return (
          Array.isArray(categories) &&
          categories[0]?.id === categoryItem?.id
        );
      });

    return (
      <div className="flex justify-center items-center gap-10 flex-wrap my-10 py-10">
        {filteredProducts.map((filteredProduct) => (
          <ProductCard
            categoryItem={categoryItem}
            product={filteredProduct}
            key={filteredProduct?.id || Math.random()}
          />
        ))}
      </div>
    );
  };

  // Update the initial loading state
  if (isCategoryLoading || isProductsLoading) {
    return <LoadingSpinner />;
  }

  // Error state
  if (categoryError || productsError) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl text-red-600 mb-4">Something went wrong!</h2>
          <p className="text-gray-600">
            {categoryError?.message || productsError?.message || "Failed to load content"}
          </p>
        </div>
      </div>
    );
  }

  // Check if data exists
  if (!category?.categories || !products?.products) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p className="text-xl text-gray-600">No data available</p>
      </div>
    );
  }

  return (
    <main className="mt-4 sm:px-12 px-2 w-full max-w-[1700px] mx-auto">
      <div className="w-full h-screen">
        {/* <img
          src="/images/simplebg-content.png"
          alt="background"
          className="absolute inset-0 w-full h-full object-cover z-[-10]"
        /> */}

        <div className="relative slider-container">
          <Slider {...settings}>
            <div className="relative">
              <img
                src="/images/hero-section.png"
                alt="hero-section"
                className="mx-auto w-full object-contain"
              />
              <div className="absolute right-[25%] top-[25%] md:left-[5%] md:top-[40%] leading-tight">
                <h1 className="text-[56px] font-bold text-white">
                  Flat <br />
                  20% Discount
                </h1>
                <p className="text-white">terms and conditions applied</p>
              </div>
            </div>
            <div className="relative">
              <img
                src="/images/hero-section.png"
                alt="hero-section"
                className="mx-auto w-full object-contain"
              />
              <div className="absolute right-[25%] top-[25%] md:left-[5%] md:top-[50%]">
                <h6 className="sm:text-3xl text-sm text-white">
                  Flat <br />
                  20% Discount
                </h6>
                <p className="text-white font-thin">
                  terms and conditions applied
                </p>
              </div>
            </div>
          </Slider>
        </div>
        <div className=" ">
          <div>
            {/* <h4 className="sm:text-start text-center text-[30px] text-zinc-400">
              Our Special
            </h4> */}
            <div>
              <MenuNav
                navPosition={navPosition}
                setNavPosition={setNavPosition}
                sectionRefs={sectionRefs}
                navRef={navRef}
                category={category}
              />
            </div>
            <div className="pt-10">
              {category.categories.map((categoryItem, categoryIndex) => (
                <div
                  key={categoryItem?.id || categoryIndex}
                  ref={(el) => (sectionRefs.current[categoryIndex] = el)}
                  className="mt-20"
                >
                  <div>
                    <h2 className="text-3xl font-bold">
                      {categoryItem?.categoryData?.name || "Unnamed Category"}
                    </h2>
                    {renderProducts(categoryItem)}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-25">
            <h1 className="text-4xl text-zinc-400 sm:text-start text-center">
              Reviews
            </h1>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mt-10">
              <ReviewsCart />
              <ReviewsCart />
              <ReviewsCart />
              <ReviewsCart />
              <ReviewsCart />
              <ReviewsCart />
            </div>
            <div className="mt-[100px] mb-[24px]">
              <LocationContainer />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <Footer />
        </div>
      </div>
    </main>
  );
};

export default HomePage;
