import React, { useEffect, useState } from 'react'
import { useAllOrders } from '../../../features/orders/orders-service'
import EntriesDropdown from '../../../component/DropDown'

const CompletedOrder = () => {
  const { data: orders, isLoading, isError } = useAllOrders()
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([])
  const rowsPerPage = 3;

  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = tableData.slice(startIndex, startIndex + rowsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    if (orders?.orders) {
      // Filter only completed orders
      const completedOrders = orders.orders.filter(order => order.state === 'COMPLETED');
      setTableData(completedOrders);
    }
  }, [orders]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading orders</div>;

  return (
    <div>
      <div className="flex justify-between mt-5 ">
        <EntriesDropdown />
        <div>
          Search: <input className="h-[40px] rounded-md" type="Search" />
        </div>
      </div>
      <table className="mt-8 w-[100%]">
        <thead>
          <tr className="h-[55px] bg-[#000000CC] text-white">
            <th scope="col" className="rounded-tl-[10px] px-4 text-left">
              ID #
            </th>
            <th scope="col" className="px-4 text-left">Customer</th>
            <th scope="col" className="px-4 text-left">Amount</th>
            <th scope="col" className="px-4 text-left">Total Items</th>
            <th scope="col" className="px-4 text-left">Phone</th>
            <th scope="col" className="px-4 text-left">Status</th>
            <th scope="col" className="rounded-tr-[10px] px-4 text-left">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {currentData?.map((row, index) => (
            <tr 
              className="border-2 border-zinc-300 bg-white hover:bg-gray-50 transition-colors duration-150" 
              key={row.id}
            >
              <td className="px-4 py-4">{startIndex + index + 1}</td>
              <td className="px-4 py-4">{row.customer}</td>
              <td className="px-4 py-4">
                ${row.totalMoney?.amount ? (row.totalMoney.amount / 100).toFixed(2) : '0.00'}
              </td>
              <td className="px-4 py-4">{row.lineItems?.length || 0}</td>
              <td className="px-4 py-4">
                {row.fulfillments?.[0]?.pickupDetails?.recipient?.phoneNumber || 'N/A'}
              </td>
              <td className="px-4 py-4">
                <span className="px-2 py-1 rounded-full text-sm bg-green-100 text-green-800">
                  COMPLETED
                </span>
              </td>
              <td className="px-4 py-4">
                <div className="flex gap-2">
                  <button className="actionBtns bg-[#58C922] h-[40px] w-[40px] p-4 text-white rounded-[10px]">
                    <svg
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 5.71429L4.14286 8.85714L12 1"
                        stroke="#EDEDE9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button className="actionBtns bg-[#C92222] h-[40px] w-[40px] p-4 text-white rounded-[10px]">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 9L9 1M1 1L9 9"
                        stroke="#EDEDE9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-between mt-4 p-1">
        <span>
          Page {currentPage} of {totalPages || 1}
        </span>
        <div className="flex gap-3">
          <button
            onClick={handlePrevPage}
            className={`px-4 py-2 rounded-md transition-colors ${
              currentPage === 1 || !tableData.length
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-black text-white hover:bg-gray-800'
            }`}
            disabled={currentPage === 1 || !tableData.length}
          >
            Previous
          </button>
          <div className={`h-[40px] w-[40px] py-[8px] px-[16px] rounded-md text-center ${
            !tableData.length ? 'bg-gray-300 text-gray-500' : 'bg-[#eab43e] text-white'
          }`}>
            <span>{currentPage}</span>
          </div>
          <button
            onClick={handleNextPage}
            className={`px-4 py-2 rounded-md transition-colors ${
              currentPage === totalPages || !tableData.length
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-black text-white hover:bg-gray-800'
            }`}
            disabled={currentPage === totalPages || !tableData.length}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  )
}

export default CompletedOrder
