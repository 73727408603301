import React from "react";
import { productItems } from "../utils";
import CustomTabs from "../component/CustomTabs";
import Bagels from "./product/Bagels";
import CreamCheese from "./product/CreamCheese";
import Sandwich from "./product/Sandwich";
import Schmear from "./product/Schmear";

//Replicate products
const Products = () => {
  // const productsMultiple = Array.from(
  //   { length: 10 },
  //   (_, i) => productItems[i % productItems.length]
  // );

  const tabs = [
    { label: "bagels", content: <Bagels /> },
    { label: "cream cheese & lox", content: <CreamCheese /> },
    { label: "sandwiches", content: <Sandwich /> },
    { label: "schmear", content: <Schmear /> },
  ];

  const tabClassName =
    "text-[#001219] rounded-full py-2 md:mx-10 md:text-base text-xs px-6 md:px-16 py-4 z-0 capitalize";
  const activeTabClassName =
    "bg-white text-black rounded-full py-2 md:mx-10 px-6 md:text-base text-xs md:px-16 py-4 z-10 capitalize";
  const tabPositionClassName = "flex flex-col justify-center";

  return (
    <div className="mt-32">
      <div className="bg-primary min-w-full justify-center items-center py-8 flex">
        <CustomTabs
          tabs={tabs}
          tabClassName={tabClassName}
          activeTabClassName={activeTabClassName}
          tabPositionClassName={tabPositionClassName}
        />
      </div>
      {/* <div className="mt-16 sm:px-12 px-4 w-full max-w-[1700px] mx-auto">
        <div>Tabs</div>
        <h2 className="text-3xl">Bagels With Cream Cheese & Other Spreads</h2>
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-8 mt-20 place-items-center my-10">
          {productsMultiple.map((product, index) => (
            <div
              key={index}
              className="flex flex-col gap-5 bg-gray rounded-3xl cursor-pointer"
            >
              <div className="w-full h-full rounded-3xl overflow-hidden">
                <img
                  src={product.imgUrl}
                  alt={product.title}
                  className="w-72 h-52 object-cover"
                />
              </div>
              <div className="py-3 px-4">
                <p className="capitalize ">{product.title}</p>
                <p className="text-2xl font-bold">
                  {product.priceUnits}
                  {product.price}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default Products;
