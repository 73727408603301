import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TableComponent from "../component/Table";
import { useCategoryById } from "../features/category/category-service";
import {
  useAllProducts,
  useDeleteProduct,
} from "../features/products/products-service";
import { MdDeleteOutline } from "react-icons/md";
import { message, Popconfirm } from "antd";

const CategoryDetails = () => {
  // const [productsByCategory, setProductsByCategory] = useState();
  const params = useParams();
  const [categoryData, setCategoryData] = useState({
    id: 1,
    categoryName: "",
    item: "",
    description: "",
    // id: 2,
    // categoryName: "John Doe",
    // item: "/images/blueberry.jpg",
    // description: "Test",
  });
  console.log(params);

  const { data: category } = useCategoryById(params?.id);
  const { data: products } = useAllProducts();
  const deleteProduct = useDeleteProduct();

  console.log("CAtegory by id===>", category);

  if (!Array.isArray(products?.products)) {
    console.log("Products not yet loaded or not an array");
    return <div>Loading...</div>;
  }

  const productsByCategory = products.products.filter((pro) => {
    const check = pro?.itemData?.categories?.[0]?.id;
    // console.log("Check:", check, "Category ID:", category?.category?.id);
    return check === category?.category?.id;
  });

  // console.log("Filtered Products:", productsByCategory);

  const data = [
    {
      id: 1,
      categoryName: "John Doe",
      item: "/images/blueberry.jpg",
      description: "Test",
    },
    {
      id: 2,
      categoryName: "John Doe",
      item: "/images/blueberry.jpg",
      description: "Test",
    },
  ];

  const headers = [
    { key: "id", label: "ID #" },
    { key: "categoryName", label: "Category" },
    {
      key: "item",
      label: "Item",
      render: (value) => (
        <img src={value} alt="product" className="h-[100px] w-[100px]" />
      ),
    },
    { key: "description", label: "Description" },

    {
      key: "actions",
      label: "Actions",
      render: (_, row) => (
        <div className="flex gap-2 justify-center mt-6">
          <button className="actionBtns bg-[#58C922] h-[40px] w-[40px] p-4 text-white rounded-[10px]">
            <svg
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 5.71429L4.14286 8.85714L12 1"
                stroke="#EDEDE9"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button className="actionBtns bg-[#C92222] h-[40px] w-[40px] p-4 text-white rounded-[10px]">
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9L9 1M1 1L9 9"
                stroke="#EDEDE9"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      ),
    },
  ];

  const handleDelete = async (categoryId) => {
    try {
      await deleteProduct.mutateAsync(categoryId);
      message.success("Product deleted successfully");
    } catch (error) {
      message.error(
        "Failed to delete product: " +
          (error.response?.data?.details || error.message)
      );
    }
  };
  return (
    <div>
      {/* <TableComponent headers={headers} data={data} /> */}
      {productsByCategory.length > 0 ? (
        <table className="mt-8 w-[100%]">
          <thead>
            <tr className="h-[55px] bg-[#000000CC] text-white">
              <th scope="col" className="rounded-tl-[10px] ">
                ID #
              </th>
              <th scope="col">Product</th>
              <th scope="col">Image</th>
              <th scope="col">Description</th>
              <th scope="col">Price</th>
              <th scope="col" className="rounded-tr-[10px] ">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {productsByCategory.map((row, index) => (
              <tr className="border-2 border-zinc-300" key={index}>
                <th scope="row">{index + 1}</th>
                <td className="text-center">{row?.itemData?.name}</td>
                <td className="h-[100px] w-[100px]">
                  <img src={row?.image} alt="product" />
                </td>
                <td className="text-center">{row?.itemData?.description}</td>
                <td className="text-center">
                  $
                  {
                    row?.itemData?.variations[0]?.itemVariationData?.priceMoney
                      ?.amount
                  }
                </td>
                {/* <td className="text-center">{row.amount}</td>
              <td className="text-center">{row.status}</td> */}
                <td className="flex gap-2 justify-center mt-6 ">
                  <button className="actionBtns bg-[#58C922] h-[40px] w-[40px] p-4 text-white rounded-[10px]">
                    <svg
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 5.71429L4.14286 8.85714L12 1"
                        stroke="#EDEDE9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>

                  <Popconfirm
                    title="Delete Product"
                    description="Are you sure you want to delete this product?"
                    onConfirm={() => handleDelete(row?.id)}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ danger: true }}
                  >
                    <button className="actionBtns flex justify-center items-center bg-[#C92222] h-[40px] w-[40px] text-white rounded-[10px]">
                      <MdDeleteOutline />
                    </button>
                  </Popconfirm>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Products found</p>
      )}
    </div>
  );
};

export default CategoryDetails;
