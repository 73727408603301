import { useState } from "react";

const LocationContainer = () => {
  const [activeTab, setActiveTab] = useState("Location1");

  const locations = [
    {
      id: "Location1",
      name: "Area Name, City",
      address: "1810 Peachtree Pkwy, Suite 108 Cumming, GA 30041",
      phone: "404-626-3373",
      email: "thebagelhole@gmail.com",
      timings: [
        "Tuesday - Friday: 6:30am - 2pm",
        "Saturday - Sunday: 6:30am - 2pm",
        "Monday: Closed",
      ],
    },
    {
      id: "Location2",
      name: "Another Area, City",
      address: "123 Main St, Suite 456 Another City, XY 78910",
      phone: "123-456-7890",
      email: "anotheremail@gmail.com",
      timings: [
        "Tuesday - Friday: 7:00am - 3pm",
        "Saturday - Sunday: 7:00am - 3pm",
        "Monday: Closed",
      ],
    },
    {
      id: "Location3",
      name: "Third Area, City",
      address: "789 Elm St, Suite 101 Third City, AB 12345",
      phone: "987-654-3210",
      email: "thirdemail@gmail.com",
      timings: [
        "Tuesday - Friday: 8:00am - 4pm",
        "Saturday - Sunday: 8:00am - 4pm",
        "Monday: Closed",
      ],
    },
  ];

  return (
    <div className="location-container w-[80%]  mx-auto mt-[72px] mb-12">
 
      <div className="location-tabs flex justify-center md:gap-8 gap-2 mb-6 ">
        {locations.map((location) => (
          <button
            key={location.id}
            className={`tablink px-4 hover:bg-primary bg-[#F1F1F1] capitalize px-5 py-3 rounded-3xl hover:bg-primary ${
              activeTab === location.id
                ? "bg-primary text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setActiveTab(location.id)}
          >
            {location.name.split(",")[0]}
          </button>
        ))}
      </div>

     <div className="rounded-[8px] md:flex block w-full md:h-[540px] h-full mt-[32px]">
     <div className="location-content bg-gray-50 rounded-l-3xl p-[100px] shadow-md md:w-[90%] w-[100%] h-full bg-[#EBEBEB]">
        {locations.map(
          (location) =>
            activeTab === location.id && (
              <div key={location.id} className="location-info">
                <h3 className="text-xl font-bold mb-2">{location.name}</h3>
                <p className="mb-2">
                  <strong>Address:</strong> <br /> {location.address}
                </p>

                <p className="mb-2">
                  <strong>Phone:</strong> <br /> {location.phone}
                </p>
                <p className="mb-2">
                  <strong>Email:</strong> <br /> {location.email}
                </p>
                <p className="mb-2">
                  <strong>Timings:</strong>
                  <br />
                  {location.timings.map((time, index) => (
                    <span key={index} className="block">
                      {time}
                    </span>
                  ))}
                </p>
              </div>
            )
        )}
      </div>

      <div className="location-map  w-[100%] h-[540px]">
        <iframe
        title="location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3198445.3590642507!2d-119.66266298542811!3d38.47652324223378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80990aa1f8deb471%3A0xcf47038aaafc95b3!2sNevada%2C%20USA!5e0!3m2!1sen!2s!4v1722286221346!5m2!1sen!2s"
          width="100%"
          height="100%"
          className="rounded-r-3xl  shadow-md"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
     </div>
    </div>
  );
};

export default LocationContainer;
