import React, { useState } from "react";

const EntriesDropdown = () => {
  const [entries, setEntries] = useState(10);

  const handleChange = (event) => {
    setEntries(event.target.value);
    console.log("Selected entries:", event.target.value); 
  };

  return (
    <div className="flex items-center space-x-2">
      <label htmlFor="entries" className="text-gray-700 text-sm font-medium">
        Show
      </label>
      <select
        id="entries"
        value={entries}
        onChange={handleChange}
        className="border border-gray-300 rounded-lg px-2 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
      <span className="text-gray-700 text-sm font-medium">entries</span>
    </div>
  );
};

export default EntriesDropdown;
