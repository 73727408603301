import React from "react";
import { Link } from "react-router-dom";

const AdminProductCard = ({ id, imageSrc, title, onClick }) => {
  // console.log("Image URL===>", imageSrc);
  return (
    <Link to={`/admin/category-details/${id}`} className="prodCardContainer">
      <div
        className="card prodCard cursor-pointer bg-white shadow-md rounded-lg overflow-hidden transform transition-transform hover:scale-105"
        onClick={() => onClick(id)}
      >
        <img
          src={imageSrc}
          alt={`${title}-image`}
          className="w-full h-48 object-cover bg-center"
        />
        <div className="card-content p-4">
          <h3 className="text-lg font-semibold text-zinc-800 text-center">
            {title}
          </h3>
        </div>
      </div>
    </Link>
  );
};

export default AdminProductCard;
