import React from "react";

const CreamCheese = () => {
  return (
    <div className="mt-16 sm:px-12 px-4 w-full max-w-[1700px] mx-auto">
      CreamCheese
    </div>
  );
};

export default CreamCheese;
