import { Form, Input, Upload, Button, message } from "antd";
import React, { useState } from "react";
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useCreateCategory } from "../../../features/category/category-service";
import axios from "axios";
import { handleImageUpload } from "../../../hooks/useImage.js";

const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";

const AddCategory = () => {
  const [form] = Form.useForm();
  const [mainImageLoading, setMainImageLoading] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);

  // Create category mutation
  const createCategory = useCreateCategory();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // Handle image upload


  // Handle form submission
  const onFinish = async (values) => {
    try {
      // Upload main image if exists
      let mainImageId = null;
      if (values.mainImage?.[0]?.originFileObj) {
        mainImageId = await handleImageUpload(
          values.mainImage[0].originFileObj,
          setMainImageLoading
        );
      }


      console.log(mainImageId,"mainImageIdmainImageId");
      // Upload banner if exists
      let bannerId = null;
      if (values.banner?.[0]?.originFileObj) {
        bannerId = await handleImageUpload(
          values.banner[0].originFileObj,
          setBannerLoading
        );
      }

      // Create category
      await createCategory.mutateAsync({
        name: values.title,
        description: values.description,
        mainImageId,
        bannerId
      });

      message.success('Category created successfully');
      form.resetFields();
    } catch (error) {
      message.error('Failed to create category');
      console.error('Create category error:', error);
    }
  };

  return (
    <div className="my-10 w-full">
      <div className="bg-[#e5e5e2] rounded-3xl p-4">
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          style={{ width: "100%" }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Category display image"
            name="mainImage"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: 'Please upload a display image' }]}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false} // Prevent auto upload
            >
              {mainImageLoading ? (
                <LoadingOutlined />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Category Title"
            name="title"
            rules={[{ required: true, message: 'Please enter category title' }]}
          >
            <Input
              size="large"
              placeholder="Enter category name"
            />
          </Form.Item>

          <Form.Item
            label="Category banner"
            name="banner"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
            >
              {bannerLoading ? (
                <LoadingOutlined />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Category Description"
            name="description"
            rules={[{ required: true, message: 'Please enter category description' }]}
          >
            <Input.TextArea
              placeholder="Enter category description"
              rows={4}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={createCategory.isLoading || mainImageLoading || bannerLoading}
              className="bg-blue-500 hover:bg-blue-600"
            >
              Create Category
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddCategory;
