import { Button, Form, Input, message, Modal, Upload } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

import React, { useState } from "react";
import { useUpdateCategory } from "../../../features/category/category-service";
import { handleImageUpload, useImageUrl } from "../../../hooks/useImage";
import axios from "axios";

const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";

const CategoryForm = ({ isShowEdit, setIsShowEdit, categoryData }) => {
  const [mainImageLoading, setMainImageLoading] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [form] = Form.useForm();

  const updateCategory = useUpdateCategory();
  const imageUrl = useImageUrl(categoryData.categoryData.imageIds?.[0]);

  console.log("Category image url===>", imageUrl);

  const onFinish = async (values) => {
    console.log("Values from edit form submission:", values);
    try {
      // Upload main image if exists
      let mainImageId = null;
      if (values.mainImage?.[0]?.originFileObj) {
        mainImageId = await handleImageUpload(
          values.mainImage[0].originFileObj,
          setMainImageLoading
        );
      }

      console.log(mainImageId, "mainImageIdmainImageId");
      // Upload banner if exists
      let bannerId = null;
      if (values.banner?.[0]?.originFileObj) {
        bannerId = await handleImageUpload(
          values.banner[0].originFileObj,
          setBannerLoading
        );
      }

      const data = {
        name: values.title,
        description: values.description,
        ...(mainImageId && { mainImageId }),
        ...(bannerId && { bannerId }),
      };

      await updateCategory.mutateAsync({ id: categoryData.id, data });
      message.success("Category updated successfully");
      setIsShowEdit(false);
    } catch (error) {
      message.error("Failed to update category");
      console.error("Update category error:", error);
    }
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList || [];
  };

  if (isShowEdit) {
    console.log("Category edit Data========>", categoryData);
  }

  return (
    <Modal
      title="Edit Category"
      open={isShowEdit}
      footer={null}
      onCancel={() => setIsShowEdit(false)}
    >
      {/* <Form
        form={form}
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onFinish}
        footer={null}
        initialValues={{
          title: categoryData?.categoryData?.name,
          mainImage: categoryData?.categoryData?.imageIds?.[0]
            ? [
                {
                  uid: "-1",
                  name: "mainImage.png",
                  status: "done",
                  url: `${categoryData.categoryData.imageIds[0]}`,
                },
              ]
            : [],
        }}
      >
        <Form.Item
          label="Category Display Image"
          name="mainImage"
          valuePropName="fileList"
          //   rules={[{ required: true, message: "Please upload a display image" }]}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            getValueFromEvent={normFile}
            style={{ width: "100%" }} // Ensures Upload component is full width
          >
            {mainImageLoading ? (
              <LoadingOutlined />
            ) : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Category Title"
          name="title"
          //   rules={[{ required: true, message: "Please enter category title" }]}
          style={{ width: "100%" }} // Form item full width
        >
          <Input
            size="large"
            placeholder="Enter category name"
            style={{ width: "100%" }} // Input field full width
          />
        </Form.Item>

        <Form.Item
          label="Category Banner"
          name="banner"
          valuePropName="fileList"
          style={{ width: "100%" }} // Form item full width
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            getValueFromEvent={normFile}
            style={{ width: "100%" }} // Ensures Upload component is full width
          >
            {bannerLoading ? (
              <LoadingOutlined />
            ) : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Category Description"
          name="description"
          //   rules={[
          //     { required: true, message: "Please enter category description" },
          //   ]}
          style={{ width: "100%" }} // Form item full width
        >
          <Input.TextArea
            placeholder="Enter category description"
            rows={4}
            style={{ width: "100%" }} // TextArea full width
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="bg-blue-500 hover:bg-blue-600"
            style={{ width: "100%" }} // Full width button
          >
            Update Category
          </Button>
        </Form.Item>
      </Form> */}

      <Form
        form={form}
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onFinish}
        initialValues={{
          title: categoryData?.categoryData.name || "",
          description: "",
          mainImage: categoryData?.imageIds?.[0]
            ? [
                {
                  uid: "-1",
                  name: "mainImage.png",
                  status: "done",
                  url: `/path/to/images/${categoryData?.categoryData.imageIds[0]}`, // Replace with your image base URL
                },
              ]
            : [],
        }}
      >
        <Form.Item
          label="Category Display Image"
          name="mainImage"
          valuePropName="fileList"
          getValueFromEvent={normFile} // Ensure consistent fileList structure
          rules={[{ required: true, message: "Please upload a display image" }]}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            style={{ width: "100%" }}
          >
            {mainImageLoading ? (
              <LoadingOutlined />
            ) : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Category Title"
          name="title"
          style={{ width: "100%" }}
        >
          <Input size="large" placeholder="Enter category name" />
        </Form.Item>

        <Form.Item
          label="Category Banner"
          name="banner"
          valuePropName="fileList"
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            beforeUpload={() => false}
            getValueFromEvent={normFile}
          >
            {bannerLoading ? (
              <LoadingOutlined />
            ) : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          label="Category Description"
          name="description"
          style={{ width: "100%" }}
        >
          <Input.TextArea rows={4} placeholder="Enter category description" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="bg-blue-500 hover:bg-blue-600"
            style={{ width: "100%" }}
          >
            Update Category
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryForm;
