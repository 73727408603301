import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { fetchAll } from "../../Apis/Apis";


export const GET_ALL_PRODUCTS = ['products'];
export const GET_PRODUCT_BY_ID = ['product'];

const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";


// Get all products
export const useAllProducts = () => {
  return useQuery({
    queryKey: [GET_ALL_PRODUCTS],
    queryFn: () => fetchAll('/api/products'),
  });
};

// Get product by ID
export const useProductById = (id) => {
  return useQuery({
    queryKey: [GET_PRODUCT_BY_ID, id],
    queryFn: () => axios.get(`/api/product/${id}`).then(res => res.data),
    enabled: !!id, // Only run query if ID exists
  });
};

// Create product
export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newProduct) => axios.post(`${apiUrl}/api/product`, newProduct),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALL_PRODUCTS]);
    },
    onError: (error) => {
      console.error('Error creating product:', error);
    }
  });
};

// Update product
export const useUpdateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }) => axios.put(`/api/product/${id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALL_PRODUCTS]);
    },
    onError: (error) => {
      console.error('Error updating product:', error);
    }
  });
};

// Delete product
export const useDeleteProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => axios.delete(`${apiUrl}/api/product/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_ALL_PRODUCTS]);
    },
    onError: (error) => {
      console.error('Error deleting product:', error);
    }
  });
};