import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";
// Query Keys
export const CATEGORY_KEYS = {
  all: ['categories'],
  byId: (id) => ['category', id],
  withItems: ['categories', 'with-items'],
  items: (categoryId) => ['category', categoryId, 'items']
};

// Get all categories
export const useAllCategories = () => {
  return useQuery({
    queryKey: CATEGORY_KEYS.all,
    queryFn: () => axios.get(`${apiUrl}/api/category`).then(res => res.data)
  });
};

// Get all categories with items
export const useAllCategoriesWithItems = () => {
  return useQuery({
    queryKey: CATEGORY_KEYS.withItems,
    queryFn: () => axios.get(`${apiUrl}/api/category/with-items`).then(res => res.data)
  });
};

// Get category by ID
export const useCategoryById = (id) => {
  return useQuery({
    queryKey: CATEGORY_KEYS.byId(id),
    queryFn: () => axios.get(`${apiUrl}/api/category/${id}`).then(res => res.data),
    enabled: !!id
  });
};

// Get category items
export const useCategoryItems = (categoryId) => {
  return useQuery({
    queryKey: CATEGORY_KEYS.items(categoryId),
    queryFn: () => axios.get(`${apiUrl}/api/category/${categoryId}/items`).then(res => res.data),
    enabled: !!categoryId
  });
};

// Create category
export const useCreateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newCategory) => axios.post(`${apiUrl}/api/category`, newCategory),
    onSuccess: () => {
      queryClient.invalidateQueries(CATEGORY_KEYS.all);
      queryClient.invalidateQueries(CATEGORY_KEYS.withItems);
    },
    onError: (error) => {
      console.error('Error creating category:', error);
    }
  });
};

// Update category
export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }) => axios.put(`${apiUrl}/api/category/${id}`, data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(CATEGORY_KEYS.all);
      queryClient.invalidateQueries(CATEGORY_KEYS.byId(variables.id));
      queryClient.invalidateQueries(CATEGORY_KEYS.withItems);
    },
    onError: (error) => {
      console.error('Error updating category:', error);
    }
  });
};

// Delete category
export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => axios.delete(`${apiUrl}/api/category/${id}`),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries(CATEGORY_KEYS.all);
      queryClient.invalidateQueries(CATEGORY_KEYS.byId(id));
      queryClient.invalidateQueries(CATEGORY_KEYS.withItems);
    },
    onError: (error) => {
      console.error('Error deleting category:', error);
    }
  });
};