import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

export const getImageUrl = async (imageId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/image/${imageId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
};

