import React, { useState, useEffect } from "react";
import { getImageUrl } from "../features/custom/custom";
import axios from "axios";
import { message } from "antd";

// Helper hook for React components
const apiUrl = process.env.REACT_APP_BASE_URL;

export const useImageUrl = (imageId) => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        if (imageId) {
            const fetchImageUrl = async () => {
                const imageUrl = await getImageUrl(imageId);
                setImageUrl(imageUrl.url);
            }
            fetchImageUrl();
        }
    }, [imageId]);

    return imageUrl;
};


export const handleImageUpload = async (file, setLoading) => {
    setLoading(true);
    try {
        const formData = new FormData();
        formData.append('image', file);

        // Replace with your image upload endpoint
        const response = await axios.post(`${apiUrl}/api/upload`, formData);
        setLoading(false);
        return response.data.image.id
    } catch (error) {
        setLoading(false);
        message.error('Failed to upload image');
        return null;
    }
};