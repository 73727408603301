import React, { useState } from "react";
import EntriesDropdown from "./DropDown";

const TableComponent = ({ headers, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 2;
  const tableData = [
    {
      id: "25896",
      customer: "Jefferson clay",
      item: "/images/salad.jpg",
      phone: "200 400 200",
      amount: "$11.00",
      status: "accepted",
    },
    {
      id: "25897",
      customer: "John Doe",
      item: "/images/salad.jpg",
      phone: "300 500 300",
      amount: "$15.00",
      status: "pending",
    },
    {
      id: "25898",
      customer: "Jane Smith",
      item: "/images/salad.jpg",
      phone: "400 600 400",
      amount: "$12.00",
      status: "rejected",
    },
    {
      id: "25899",
      customer: "Alice Johnson",
      item: "/images/salad.jpg",
      phone: "500 700 500",
      amount: "$20.00",
      status: "accepted",
    },
  ];

  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = tableData.slice(startIndex, startIndex + rowsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (
    <div>
      <div className="flex justify-between mt-5 ">
        <EntriesDropdown />
        <div>
          Search: <input className="h-[40px] rounded-md" type="Search" />
        </div>
      </div>
      <table className="mt-8 w-[100%]">
        <thead>
          <tr className="h-[55px] bg-[#000000CC] text-white">
            <th scope="col" className="rounded-tl-[10px] ">
              ID #
            </th>
            <th scope="col">Customer</th>
            <th scope="col">Item</th>
            <th scope="col">Phone</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col" className="rounded-tr-[10px] ">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, index) => (
            <tr className="border-2 border-zinc-300" key={index}>
              <th scope="row">{row.id}</th>
              <td className="text-center">{row.customer}</td>
              <td className="h-[100px] w-[100px]">
                <img src={row.item} alt="product" />
              </td>
              <td className="text-center">{row.phone}</td>
              <td className="text-center">{row.amount}</td>
              <td className="text-center">{row.status}</td>
              <td className="flex gap-2 justify-center mt-6 ">
                <button className="actionBtns bg-[#58C922] h-[40px] w-[40px] p-4 text-white rounded-[10px]">
                  <svg
                    width="13"
                    height="10"
                    viewBox="0 0 13 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5.71429L4.14286 8.85714L12 1"
                      stroke="#EDEDE9"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button className="actionBtns bg-[#C92222] h-[40px] w-[40px] p-4 text-white rounded-[10px]">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L9 1M1 1L9 9"
                      stroke="#EDEDE9"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <table className="mt-8 w-full border-collapse">
        <thead>
          <tr className="h-14 bg-[#000000CC] text-white">
            {headers.map((header, index) => (
              <th
                key={index}
                scope="col"
                className={`${
                  index === 0
                    ? "rounded-tl-xl" // Top-left rounded corner
                    : index === headers.length - 1
                    ? "rounded-tr-xl" // Top-right rounded corner
                    : ""
                } px-4 py-2`}
              >
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr className="border-2 border-zinc-300" key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td
                  key={colIndex}
                  className={header.className || "text-center"}
                >
                  {header.render
                    ? header.render(row[header.key], row)
                    : row[header.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table> */}

      <div className="flex items-center justify-between mt-4 p-1">
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <div className="flex gap-3">
          <button
            onClick={handlePrevPage}
            className="px-4 py-2 bg-gray-200 rounded-md bg-black text-white"
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <div className="h-[40px] w-[40px] py-[8px] px-[16px] bg-[#eab43e] text-white rounded-md ">
            <span>1</span>
          </div>
          <button
            onClick={handleNextPage}
            className="px-4 py-2 bg-gray-200 rounded-md bg-black text-white "
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
