import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { login } from "../features/auth/authService"; // Adjust this path according to your project structure
import { LOGIN_SUCCESS, LOGIN_FAILURE } from "../features/auth/actionTypes"; // Adjust this path according to your project structure
import {
  useForm,
  Resolver,
  SubmitHandler,
  Controller,
  Form,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Signin = () => {
  // const [userEmail, setUserEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const dispatch = useDispatch();
  const { isUserAuth, isAdminAuth } = useSelector((state) => state.auth);
  const location = useLocation();


  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character",
        )
        .required("Password is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    if (status === "verified") {
    console.log('verify')
    }
  }, [location.search]);

  const onSubmit = async (data) => {
    const { email, password } = data;
    setisLoading(true);

    try {
      const response = await login({ email: email, password });
      const data = response.data;

      dispatch({ type: LOGIN_SUCCESS, payload: data });

      if (data.role === "user" || data.role === "admin") {
       
        console.log( "You have successfully signed in.")
       
        if (data.role === "user") {
          navigate("/");
        } else if (data.role === "admin") {
          navigate("/admin/main");
        }
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);

      console.log(error, "errorerrorerror");
     console.log(error.message)
    }
  };

  useEffect(() => {
    if (isUserAuth) {
      navigate("/");
    } else if (isAdminAuth) {
      navigate("/admin/main");
    }
  }, [isUserAuth, isAdminAuth, navigate]);

  if (isAdminAuth || isUserAuth) return;

  return (
    <div className="flex h-full w-full items-center justify-center">
      <img
        src="/images/yellowbg-content.png"
        alt="background"
        className="absolute inset-0 w-full h-full object-cover z-[-10]"
      />
      <div className="grid w-full grid-cols-1 items-center justify-between md:grid-cols-2">
        <div className="hidden items-center justify-center md:flex">
          <img src="/images/logo.svg" alt="logo" width={600} />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex min-h-screen w-full flex-col items-center justify-center gap-6"
        >
          <img
            src="/images/logo.svg"
            alt="logo"
            className="flex md:hidden"
            width={300}
          />

          <h1 className="text-4xl text-center text-black uppercase font-bold">
            Knead it, eat it <br />
            love it!!!
          </h1>
          <p className="space-y-5 text-black font-semibold text-center">Come nosh on a bigger, better New <br /> York style bagel</p>
          <div className="flex w-full flex-col items-center justify-center gap-6 px-4 sm:px-0">
          <div className="w-[50%]">
              <input
                type="email"
                placeholder="Enter email"
                className="w-full rounded-lg p-3 outline-none"
              

                {...register("email")}
              />

              {errors?.email && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.email?.message}
                </p>
              )}
            </div>
            <div className="w-[50%] ">
              <input
                type="password"
                placeholder="Enter password"
                className="w-full rounded-lg p-3 outline-none"
               
                disabled={isLoading}
                {...register("password")}
              />
              {errors?.password && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.password?.message}
                </p>
              )}
            </div>
            <button type="submit" className="w-1/2 rounded-lg bg-black p-3 text-white">
              SignIn
            </button>
          </div>
          {/* <p className="text-center text-sm text-gray-200">
            OR <br />
            <br />
            If you don&aps;t have an account, click to {""}
            <Link
              to="/signup"
              className="cursor-pointer font-semibold uppercase underline"
            >
              Signup
            </Link>
          </p> */}
        </form>
      </div>
    </div>
  );
};

export default Signin;
