import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Navbar from "./component/Navbar";
import Signin from "./pages/Signin";
import SignUp from "./pages/SignUp";
import Main from "./dashboard/Main";
import MainLayout from "./layout/MainLayout";
import AdminLayout from "./layout/AdminLayout";
import Products from "./pages/Products";
import Cart from "./pages/Cart.jsx";
import Checkout from "./pages/Checkout.jsx";

import Menu from "./pages/Menu.jsx";
import Category from "./pages/Category.jsx";
import { useSelector } from "react-redux";
import CategoryDetails from "./pages/CategoryDetails.jsx";


function App() {
  //Admin Layout
  const AdminRouteWrapper = ({ element, redirect }) => {
    const isAdminAuth = useSelector((state) => state.auth.isAdminAuth);

    return isAdminAuth ? (
      <AdminLayout>{element}</AdminLayout>
    ) : (
      <Navigate to={redirect} />
    );
  };

  //Public Layout
  const PublicRouteWrapper = ({ element }) => {
    return <MainLayout>{element}</MainLayout>;
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<PublicRouteWrapper element={<HomePage />} />}
        />

        <Route
          path="/category"
          element={<PublicRouteWrapper element={<Category />} />}
        />

        <Route
          path="/products"
          element={<PublicRouteWrapper element={<Products />} />}
        />
        <Route
          path="/menu"
          element={<PublicRouteWrapper element={<Menu />} />}
        />

        <Route
          path="/cart"
          element={<PublicRouteWrapper element={<Cart />} />}
        />
        <Route
          path="/checkout"
          element={<PublicRouteWrapper element={<Checkout />} />}
        />


        <Route
          path="/signin"
          element={<PublicRouteWrapper element={<Signin />} />}
        />
        <Route
          path="/signup"
          element={<PublicRouteWrapper element={<SignUp />} />}
        />
        <Route
          path="/admin/:subpage?"
          element={<AdminRouteWrapper element={<Main />} redirect={"/signin"} />}
        />

        <Route
          path="/admin/:subpage?/:id"
          element={<AdminRouteWrapper element={<Main />} redirect={"/signin"} />}
        />
      </Routes>
    </>
  );
}

export default App;
