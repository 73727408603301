import React, { useState } from "react";

const ReviewBar = () => {
  const [activeTab, setActiveTab] = useState("complaints");

  const tabs = [
    { id: "complaints", label: "Complaints" },
    { id: "reviews", label: "Reviews" },
  ];

  const tabContent = {
    complaints: [
      {
        name: "Jefferson Clay",
        time: "10 minutes ago",
        message:
          "Lorem ipsum dolor sit amet consectetur. Convallis turpis suspendisse lacus interdum sit pellentesque eros ut. Lorem nullam ac massa morbi faucibus pulvinar in dignissim.",
        imgSrc: "/images/salad.jpg",
      },
    
      {
        name: "John Doe",
        time: "20 minutes ago",
        message:
          "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
        imgSrc: "/images/salad.jpg",
      },
    
     
    ],
    reviews: [
      {
        name: "Alice Brown",
        time: "5 minutes ago",
        message:
          "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
        imgSrc: "/images/salad.jpg",
      },
      {
        name: "Chris Johnson",
        time: "15 minutes ago",
        message:
          "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.",
        imgSrc: "/images/salad.jpg",
      },
    ],
  };

  return (
    <div className="lg:col-span-5 border border-zinc-300 shadow-lg rounded-3xl mt-4 bg-[#E5E5E2]">

      <div className="flex space-x-4">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`px-4 py-2 text-lg font-medium ${
              activeTab === tab.id
                ? "border-b-2 border-b-black"
                : "text-gray-700 bg-gray-200"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>


      <div className="mt-4 space-y-4  overflow-y-scroll max-h-[500px]">
        {tabContent[activeTab].map((item, index) => (
          <div
            key={index}
            className="flex justify-between gap-10 p-4 bg-gray-100 rounded-lg"
          >

            <div className="w-24 h-24 overflow-hidden rounded-lg">
              <img
                src={item.imgSrc}
                alt={item.name}
                className="w-full h-full object-cover"
              />
            </div>
   
            <div className="ml-4 w-[80%]">
              <h4 className="text-xl font-semibold">{item.name}</h4>
              <small className="block text-gray-500">{item.time}</small>
              <p className="mt-2 text-gray-700">{item.message}</p>
              <a
                href="#"
                className="inline-block mt-2 text-black hover:underline"
              >
                View Message
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewBar;
