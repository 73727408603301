import { Button, Form, Input, message, Select, Upload } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import {
  useAllProducts,
  useCreateProduct,
} from "../../../features/products/products-service";
import axios from "axios";
import {
  useAllCategories,
  useAllCategoriesWithItems,
} from "../../../features/category/category-service";
import { handleImageUpload } from "../../../hooks/useImage";

const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";

const AddMenuItem = () => {
  const [form] = Form.useForm();
  const [itemImageLoading, setItemImageLoading] = useState(false);
  const [bannerLoading, setBannerLoading] = useState(false);

  const createProduct = useCreateProduct();
  const { data: products } = useAllProducts();
  const { data: categories } = useAllCategoriesWithItems();

  const categoryData = products?.products.map((product) => product?.image);

  console.log("All products===>", products);
  // console.log("All categories===>", categories);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // Handle form submission
  const onFinish = async (values) => {
    try {
      // Upload main image if exists
      let mainImageId = null;
      if (values.itemImage?.[0]?.originFileObj) {
        mainImageId = await handleImageUpload(
          values.itemImage[0].originFileObj,
          setItemImageLoading
        );
      }

      console.log("Product create values===>", values);
      // Create category
      await createProduct.mutateAsync({
        name: values.title,
        description: values.description,
        mainImageId,
        price: values.price,
        quantity: values.quantity,
        categoryId: values.category,
        locationId: "L65JN4NMA8QPZ",
        variations: [
          {
            type: "ITEM_VARIATION",
            itemVariationData: {
              name: "Small",
              pricingType: "FIXED_PRICING",
              priceMoney: {
                amount: values.price,
                currency: "USD",
              },
              imageIds: ["27YOB55PCFLWMYRZ4CKUDRIS"],
            },
          },
        ],
      });

      message.success("Product created successfully");
      form.resetFields();
    } catch (error) {
      message.error("Failed to create product");
      console.error("Create product error:", error);
    }
  };

  return (
    <div className="my-10 w-full">
      <div className="bg-[#e5e5e2] rounded-3xl p-4">
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          style={{ width: "100%" }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Item Image"
            name="itemImage"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              { required: true, message: "Please upload a display image" },
            ]}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false} // Prevent auto upload
            >
              {itemImageLoading ? (
                <LoadingOutlined />
              ) : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label="Product Title"
            name="title"
            rules={[{ required: true, message: "Please enter product title" }]}
          >
            <Input size="large" placeholder="Enter category name" />
          </Form.Item>

          <Form.Item name="category" label="Product Category">
            <Select
              defaultValue="Select Category"
              style={{
                width: "100%",
              }}
              options={categories.categories?.map((variation) => ({
                value: variation.id,
                label: variation?.categoryData.name,
              }))}
            />
          </Form.Item>

          <Form.Item name="variation" label="Product Variation">
            <Select
              defaultValue="Select Variation"
              style={{
                width: "100%",
              }}
              options={[
                {
                  value: "lucy",
                  label: "Lucy",
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Product Price"
            name="price"
            rules={[{ required: true, message: "Please enter product price" }]}
          >
            <Input
              prefix="$"
              suffix="USD"
              size="large"
              placeholder="Enter product price"
            />
          </Form.Item>

          <Form.Item
            label="Product Quantity"
            name="quantity"
            rules={[
              { required: true, message: "Please enter product quantity" },
            ]}
          >
            <Input size="large" placeholder="Enter product quantity" />
          </Form.Item>

          <Form.Item
            label="Product Description"
            name="description"
            rules={[
              { required: true, message: "Please enter product description" },
            ]}
          >
            <Input.TextArea placeholder="Enter product description" rows={4} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={createProduct.isLoading}
              className="bg-blue-500 hover:bg-blue-600"
            >
              Create Product
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddMenuItem;
