import { FaChevronLeft, FaChevronRight } from "react-icons/fa";


//Slick Controls
// Left Arrow
export const PrevArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-zinc-100 p-2 rounded-full z-1 shadow hover:bg-zinc-200"
      onClick={onClick}
      style={{ zIndex: 1000 }}
    >
      <FaChevronLeft size={16} className="text-xl text-zinc-600" />
    </button>
  );
};

// Right Arrow
export const NextArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-zinc-100 p-2 rounded-full z-1 shadow hover:bg-zinc-200"
      onClick={onClick}
      style={{ zIndex: 1000 }}
    >
      <FaChevronRight size={16} className="text-xl text-zinc-600" />
    </button>
  );
};


export const featuredProducts = [
  {
    imgUrl: "/images/bagels.jpg",
    title: "bagels",
  },
  {
    imgUrl: "/images/sandwich.jpg",
    title: "sandwich",
  },
  {
    imgUrl: "/images/schmear.jpg",
    title: "schmear",
  },
  {
    imgUrl: "/images/salad.jpg",
    title: "salad",
  },
  {
    imgUrl: "/images/bagels.jpg",
    title: "bagels",
  },
  {
    imgUrl: "/images/salad.jpg",
    title: "salad",
  },
];

export const productItems = [
  {
    imgUrl: "/images/blueberry.jpg",
    title: "blueberry bagel",
    price: 2.2,
    priceUnits: "$",
  },
  {
    imgUrl: "/images/blueberry-bagel.jpg",
    title: "blueberry bagel",
    price: 2.2,
    priceUnits: "$",
  },
  {
    imgUrl: "/images/salad-bagel.jpg",
    title: "blueberry bagel",
    price: 2.2,
    priceUnits: "$",
  },
  {
    imgUrl: "/images/blueberry-bagel.jpg",
    title: "blueberry bagel",
    price: 2.2,
    priceUnits: "$",
  },
  {
    imgUrl: "/images/blueberry.jpg",
    title: "blueberry bagel",
    price: 2.2,
    priceUnits: "$",
  },
];

export const menItems = [
  {
    imgUrl: "/images/menu-bagels.jpg",
    title: "bagels",
  },
  {
    imgUrl: "/images/bigger-bakers-dozen.jpg",
    title: "bigger bakers dozen",
  },
  {
    imgUrl: "/images/cream-cheese-schmear.jpg",
    title: "cream cheese schmear",
  },
];
export const menItemsSecondary = [
  {
    imgUrl: "/images/bagel-with-egg.jpg",
    title: "bagel with egg",
  },
  {
    imgUrl: "/images/bagel-with-egg&cheese.jpg",
    title: "bagel with egg & cheese",
  },
  {
    imgUrl: "/images/bagel-sides.jpg",
    title: "bagel sides",
  },
  {
    imgUrl: "/images/lox-sandwich.jpg",
    title: "lox sandwich",
  },
];

export const buttonTitle = [
  {
    title: "bagels",
    color: "primary",
  },
  {
    title: "cream cheese & lox",
    color: "gray",
  },
  {
    title: "sandwiches",
    color: "gray",
  },
  {
    title: "schmear",
    color: "gray",
  },
];

export const orderStatusData = [
  {
    title: "pending orders",
    numbers: 1520,
  },
  {
    title: "cancelled orders",
    numbers: 1520,
  },
  {
    title: "received orders",
    numbers: 1520,
  },
  {
    title: "served orders",
    numbers: 1520,
  },
  {
    title: "new customer",
    numbers: 1520,
  },
];

export const currentData = [
  {
    id: 25896,
    customer: "Jefferson clay",
    item: 1,
    phone: " 200 400 200",
    amount: "$11.00",
    status: "Accepted",
    action: "Yes",
  },
  {
    id: 25896,
    customer: "Jefferson clay",
    item: 1,
    phone: " 200 400 200",
    amount: "$11.00",
    status: "Accepted",
    action: "Yes",
  },
];

export const categories = [
  "Bagels With Cream Cheese & Other Spreads",
  "Single Bagels To-Go",
  "Cream Cheeses & Lox To-Go",
  "Breakfast Sandwiches",
  "Drinks",
  "Drinks",
  "Drinks",
];

export const bagels_products = [
  {
    title: "Asiago Cheese Bagel",
    description: "Served with capers, red onion, tom",
    price: 5.6,
    images: [
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
    ],
    addOns: [
      {
        item: "OPEN - FACE",
        price: 1.00
      },
      {
        item: "ADD TOMATO ",
        price: 1.00
      }, {
        item: "ADD RED ONION",
        price: 1.00
      }, {
        item: "ADD LETTUCE",
        price: 1.00
      }, {
        item: "ADD KETCHUP",
        price: 1.00
      }, {
        item: "LIGHT ON SPREAD",
        price: 1.00
      }, {
        item: "ADD BACON HORSERADISH",
        price: 1.00
      },

    ],
    toast:
    {
      price: 1.00,
      toasting: [
        "not toasted",
        "toasted",
        "toasted lightly",
        "toasted double",
        "beyond toasted",
      ]
    }
    ,
    others:
    {
      price: 1.00,
      choices: [
        "plain",
        "chop chip",
        "honey nut",
        "jhal ched",
        "beyond toasted",
      ]
    },

  },
  {
    title: "Asiago Cheese Bagel",
    description: "Served with capers, red onion, tom",
    price: 4.2,
    images: [
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
    ],
    addOns: [
      {
        item: "OPEN - FACE",
        price: 1.00
      },
      {
        item: "ADD TOMATO ",
        price: 1.00
      }, {
        item: "ADD RED ONION",
        price: 1.00
      }, {
        item: "ADD LETTUCE",
        price: 1.00
      }, {
        item: "ADD KETCHUP",
        price: 1.00
      }, {
        item: "LIGHT ON SPREAD",
        price: 1.00
      }, {
        item: "ADD BACON HORSERADISH",
        price: 1.00
      },

    ],
    toast:
    {
      price: 1.00,
      toasting: [
        "not toasted",
        "toasted",
        "toasted lightly",
        "toasted double",
        "beyond toasted",
      ]
    }
    ,
    others: [
      {
        price: 1.00,
        choices: [
          "plain",
          "chop chip",
          "honey nut",
          "jhal ched",
          "beyond toasted",
        ]
      }
    ],


  },
  {
    title: "Asiago Cheese Bagel",
    description: "Served with capers, red onion, tom",
    price: 4.56,
    images: [
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
    ], addOns: [
      {
        item: "OPEN - FACE",
        price: 1.00
      },
      {
        item: "ADD TOMATO ",
        price: 1.00
      }, {
        item: "ADD RED ONION",
        price: 1.00
      }, {
        item: "ADD LETTUCE",
        price: 1.00
      }, {
        item: "ADD KETCHUP",
        price: 1.00
      }, {
        item: "LIGHT ON SPREAD",
        price: 1.00
      }, {
        item: "ADD BACON HORSERADISH",
        price: 1.00
      },

    ],
    toast:
    {
      price: 1.00,
      toasting: [
        "not toasted",
        "toasted",
        "toasted lightly",
        "toasted double",
        "beyond toasted",
      ]
    }
    ,
    others:
    {
      price: 1.00,
      choices: [
        "plain",
        "chop chip",
        "honey nut",
        "jhal ched",
        "beyond toasted",
      ]
    }
    ,


  },
  {
    title: "Asiago Cheese Bagel",
    description: "Served with capers, red onion, tom",
    price: 3.2,
    images: [
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
    ],
    addOns: [
      {
        item: "OPEN - FACE",
        price: 1.00
      },
      {
        item: "ADD TOMATO ",
        price: 1.00
      }, {
        item: "ADD RED ONION",
        price: 1.00
      }, {
        item: "ADD LETTUCE",
        price: 1.00
      }, {
        item: "ADD KETCHUP",
        price: 1.00
      }, {
        item: "LIGHT ON SPREAD",
        price: 1.00
      }, {
        item: "ADD BACON HORSERADISH",
        price: 1.00
      },

    ],
    toast:
    {
      price: 1.00,
      toasting: [
        "not toasted",
        "toasted",
        "toasted lightly",
        "toasted double",
        "beyond toasted",
      ]
    }
    ,
    others: [
      {
        price: 1.00,
        choices: [
          "plain",
          "chop chip",
          "honey nut",
          "jhal ched",
          "beyond toasted",
        ]
      }
    ],


  },
  {
    title: "Asiago Cheese Bagel",
    description: "Served with capers, red onion, tom",
    price: 6.00,
    images: [
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
      "/images/donut-1.png",
    ], addOns: [
      {
        item: "OPEN - FACE",
        price: 1.00
      },
      {
        item: "ADD TOMATO ",
        price: 1.00
      }, {
        item: "ADD RED ONION",
        price: 1.00
      }, {
        item: "ADD LETTUCE",
        price: 1.00
      }, {
        item: "ADD KETCHUP",
        price: 1.00
      }, {
        item: "LIGHT ON SPREAD",
        price: 1.00
      }, {
        item: "ADD BACON HORSERADISH",
        price: 1.00
      },

    ],
    toast:
    {
      price: 1.00,
      toasting: [
        "not toasted",
        "toasted",
        "toasted lightly",
        "toasted double",
        "beyond toasted",
      ]
    }
    ,
    others:
    {
      price: 1.00,
      choices: [
        "plain",
        "chop chip",
        "honey nut",
        "jhal ched",
        "beyond toasted",
      ]
    }
    ,


  }
]
