import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Button, Checkbox, Modal, Select } from "antd";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../features/cart/action";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// import { NextArrow, PrevArrow } from "../utils";

// import { Button, Modal } from "flowbite-react";

// Left Arrow
const PrevArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-zinc-100 p-2 rounded-full z-10 shadow hover:bg-zinc-200"
      onClick={onClick}
    >
      <FaChevronLeft size={16} className="text-xl text-zinc-600" />
    </button>
  );
};

// Right Arrow
const NextArrow = ({ onClick }) => {
  return (
    <button
      className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-zinc-100 p-2 rounded-full z-10 shadow hover:bg-zinc-200"
      onClick={onClick}
    >
      <FaChevronRight size={16} className="text-xl text-zinc-600" />
    </button>
  );
};

const ProductCard = ({ product, categoryItem, key }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(product?.price || 0);
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart.cart);
  const navigate = useNavigate();

  // console.log("product", product);
  // console.log("Product keys===>", product);

  const { register, handleSubmit, setValue, watch } = useForm();
  const selectedToasting = watch("toasting");
  const selectedChoices = watch("choices");
  const selectedAddOns = watch("addOns");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  // Calculate Total Price
  const calculatePrice = () => {
    let price = product?.price || 0;

    if (selectedToasting) {
      price += product?.toast[0]?.toasting?.includes(selectedToasting) ? 1 : 0;
    }

    if (selectedChoices) {
      price += product?.others[0]?.choices?.includes(selectedChoices) ? 1 : 0;
    }

    if (selectedAddOns?.length) {
      price += selectedAddOns.length;
    }

    // setTotalPrice(price.toFixed(2));
  };

  const handleChange = (field, value) => {
    setValue(field, value);
    calculatePrice();
  };

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    // addToCart({ ...data, totalPrice });
    setIsShowModal(false);
  };
  const onChange = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const handleAddToCart = () => {
    dispatch(addToCart(product));

    // Dispatch custom event for cart update
    window.dispatchEvent(new Event('cartUpdate'));

    toast.success(`${product?.itemData?.name} added to cart!`, {
      duration: 3000,
      position: "top-center",
    });
  };

  return (
    <div className="flex flex-col max-w-[400px] min-h-[300px] shadow-xl rounded-2xl border border-zinc-300">
      <Slider {...settings}>
        <div className="w-[400px] overflow-hidden object-cover h-[300px] bg-center">
          <img
            src={product?.image}
            alt={product?.title}
            className="w-full h-full object-cover rounded-t-2xl"
          />
        </div>
        <div className="w-[400px] overflow-hidden object-cover h-[300px] bg-center">
          <img
            src={product?.image}
            alt={product?.title}
            className="w-full h-full object-cover rounded-t-2xl"
          />
        </div>
        <div className="w-[400px] overflow-hidden object-cover h-[300px] bg-center">
          <img
            src={product?.image}
            alt={product?.title}
            className="w-full h-full object-cover rounded-t-2xl"
          />
        </div>
      </Slider>

      {/* Content Section */}
      <div className="flex justify-between flex-col p-6 space-y-4">
        <h3 className="font-semibold text-xl capitalize text-gray-800">
          {product?.itemData?.name}
        </h3>
        
        <p className="line-clamp-2 text-gray-600 text-sm">
          {product?.itemData?.description || "No description"}
        </p>

        {/* Action Button */}
        <div className="flex justify-end">
          <button
            onClick={() => setIsShowModal(true)}
            className="group bg-yellow-500 hover:bg-yellow-600 text-white rounded-full px-6 py-2.5 flex items-center gap-2 transition-all duration-300"
          >
            <IoMdAdd className="text-xl group-hover:rotate-90 transition-transform duration-300" />
            <span>Add to Order</span>
          </button>
        </div>
      </div>

      {/* Modal */}
      <Modal
        centered
        open={isShowModal}
        onCancel={() => setIsShowModal(false)}
        width={800}
        className="product-modal"
        style={{ margin: "20px" }}
        footer={
          <div className="flex flex-wrap justify-center gap-4 pb-4">
            <button
              key="addToCart"
              onClick={handleAddToCart}
              className="bg-yellow-500 text-white w-48 px-6 py-3 rounded-full hover:bg-yellow-600 transition-all duration-300 font-medium"
            >
              Add To Cart
            </button>
            {cart.length > 0 && (
              <button
                key="close"
                onClick={() => navigate("/checkout")}
                className="bg-blue-500 text-white w-48 px-6 py-3 rounded-full hover:bg-blue-600 transition-all duration-300 font-medium"
              >
                Go to checkout
              </button>
            )}
          </div>
        }
      >
        {/* Modal Content */}
        <div className="bg-white rounded-xl overflow-hidden">
          <Slider {...settings}>
            <div className="w-[400px] overflow-hidden object-cover h-[300px] bg-center">
              <img
                src={product?.image}
                alt={product?.title}
                className="w-full h-full object-cover rounded-t-2xl"
              />
            </div>
            <div className="w-[400px] overflow-hidden object-cover h-[300px] bg-center">
              <img
                src={product?.image}
                alt={product?.title}
                className="w-full h-full object-cover rounded-t-2xl"
              />
            </div>
            <div className="w-[400px] overflow-hidden object-cover h-[300px] bg-center">
              <img
                src={product?.image}
                alt={product?.title}
                className="w-full h-full object-cover rounded-t-2xl"
              />
            </div>
          </Slider>

          <div className="mt-8 space-y-6 max-h-[400px] overflow-y-auto px-6">
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-gray-800">
                {product?.itemData?.name}
              </h2>
              
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-xl font-semibold text-gray-800">
                  Total Price:{" "}
                  <span className="text-yellow-600">
                    ${product?.itemData?.variations[0]?.itemVariationData?.priceMoney?.amount || "N/A"}
                  </span>
                </p>
              </div>

              {/* Options Sections */}
              <div className="space-y-6 pt-4">
                <div className="space-y-3">
                  <p className="text-lg font-semibold text-gray-800">Toasting</p>
                  <Select
                    showSearch
                    placeholder="Select toasting preference"
                    optionFilterProp="label"
                    onChange={(value) => handleChange("toasting", value)}
                    style={{ width: "100%" }}
                    className="rounded-lg"
                    options={product?.toast?.toasting?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </div>
                <div>
                  <p className="text-lg font-semibold mb-3">
                    Cream Cheese/Other Spread Choices
                  </p>
                  <Select
                    showSearch
                    placeholder="Select"
                    optionFilterProp="label"
                    onChange={(value) => handleChange("choices", value)}
                    style={{ width: "100%" }}
                    // options={product?.others?.choices?.map((item) => ({
                    //   label: `${item} (+$${product?.others?.price.toFixed(
                    //     2
                    //   )})`,
                    //   value: item,
                    // }))}
                  />
                </div>
                {product?.addOns && (
                  <div className="space-y-4">
                    <p className="text-lg font-semibold">ADD-ONS:</p>
                    <Checkbox.Group
                      onChange={(value) => handleChange("addOns", value)}
                    >
                      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {product?.addOns.map((addon, index) => (
                          <div
                            key={index}
                            className="flex w-full flex-col items-start border-b border-zinc-200 pb-2 space-y-1"
                          >
                            <Checkbox value={addon.item} className="text-base">
                              <span className="text-gray-800 font-medium">
                                {addon.item}
                              </span>
                            </Checkbox>
                            <span className="text-gray-500 text-sm">
                              (+ ${addon.price.toFixed(2)})
                            </span>
                          </div>
                        ))}
                      </div> */}
                    </Checkbox.Group>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductCard;
