import React, { useState } from "react";

import { Link } from "react-router-dom";
// import {
//   menuItemBreeder,
//   menuItemCheckout,
//   menuItemPups,
//   menuItemSetting,
//   menuItemsSales,
//   menuItemUtilities,
//   menuItemWebsiteContent,
// } from "../utils/data";

import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { menuItems } from "../contants";

const SidebarCustom = ({ subpage }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleSidebarMenu = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div
      className={`no-scrollbar sticky top-0 h-screen overflow-y-auto bg-primary ease-in-out transition-all duration-300 rounded-3xl ${
        isCollapsed ? "w-72" : "w-28"
      } flex-shrink-0`}
    >
      <div
        className={`flex items-center p-4 ${
          isCollapsed ? "justify-between" : "justify-center"
        }`}
      >
        {isCollapsed && <span className="text-lg font-bold">Admin</span>}
        <button
          onClick={handleSidebarMenu}
          className="hover:bg-[#D9D9D9]/50 duration-75 rounded-full p-2"
        >
          {isCollapsed ? (
            <MdOutlineKeyboardArrowLeft size={28} />
          ) : (
            <MdOutlineKeyboardArrowRight size={28} />
          )}
        </button>
      </div>
      <div
        className={`flex w-full flex-col justify-center items-center`}
      >
        <img src="/images/logo.svg" alt="logo" width={80} />
      </div>
      <div className="mt-4 space-y-2">
        <div className="flex flex-col gap-2 py-4">
         
          {menuItems.map((menuItem, index) => (
            <Link
              to={`/admin/${menuItem.link}`}
              className={`flex items-center justify-start gap-3 font-semibold text-[#001219] duration-100 hover:bg-[#030405]/15 ${
                isCollapsed
                  ? "py-2 pl-10"
                  : "justify-center mx-6 py-3 rounded-lg"
              } ${subpage === menuItem.link && "bg-[#030405]/15 text-white"}`}
              key={index}
            >
              <menuItem.icon size={25} />
              {isCollapsed && (
                <p className="capitalize font-bold">{menuItem.menuLabel}</p>
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarCustom;
