import React from "react";
import { Link } from "react-router-dom";

const ReviewsCart = () => {
  return (
<div className="max-w-[560px] min-h-[300px] rounded-[30px] bg-[#ebebeb] p-[20px] sm:p-[30px]">
  <div className="flex flex-col sm:flex-row sm:h-[160px] gap-8">
  
    <div className="h-[70px] w-[70px] rounded-full mx-auto sm:mx-0 sm:mt-7">
      <img
        className="rounded-full h-full w-full"
        src="/images/pic.jpeg"
        alt="Profile"
      />
    </div>
 
    <div className="flex flex-col w-full sm:w-[80%] ">
      <h1 className="text-lg sm:text-[1.2em] text-[#4F4F4F] text-center sm:text-left">
        Person Name
      </h1>
      <div className="flex justify-between items-center mt-1 text-center sm:text-left">
        <span className="text-sm sm:text-[15px]">⭐ 4.5</span>
        <span className="text-sm sm:text-[15px]">02 Days Ago</span>
      </div>
      <p className="text-[#4f4f4f] line-clamp-4 text-sm sm:text-[15px] mt-2 text-center sm:text-left">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit
        suspendisse nec lectus a lacus venenatis vestibulum ut.
      </p>
      <Link
        to="#"
        className="mt-2 text-[#0000FF] text-[14px] sm:text-[16px] text-center sm:text-left"
      >
        Read Message
      </Link>
    </div>
  </div>

  <div className="mt-4 flex flex-wrap gap-2 justify-center sm:justify-start">
    <img
      className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] rounded-md"
      src="/images/reviewImgs (4).png"
      alt="Review 1"
    />
    <img
      className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] rounded-md"
      src="/images/reviewImgs (3).png"
      alt="Review 2"
    />
    <img
      className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] rounded-md"
      src="/images/reviewImgs (2).png"
      alt="Review 3"
    />
    <img
      className="h-[50px] w-[50px] sm:h-[60px] sm:w-[60px] rounded-md"
      src="/images/reviewImgs (1).png"
      alt="Review 4"
    />
  </div>
</div>

  );
};

export default ReviewsCart;
