import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  decreaseQuantity,
  increaseQuantity,
  removeFromCart,
} from "../features/cart/action";
import { FaRegTrashAlt } from "react-icons/fa";

const Cart = () => {
  const cart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();

  const handleIncrement = (id) => {
    dispatch(increaseQuantity(id));
  };

  const handleDecrement = (id) => {
    dispatch(decreaseQuantity(id));
  };

  const handleDeleteItem = (id) => {
    dispatch(removeFromCart(id));
  };

  console.log("Cart data===>", cart);

  return (
    <section className="cartMain py-8">
      <div className="container mx-auto px-4">
        <div className="mb-6">
          <h2 className="text-2xl font-bold text-start">Your Cart</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="col-span-2">
            <div className="border rounded-lg shadow-md p-4">
              <div className="border-b pb-2 mb-4">
                <h6 className="text-lg font-semibold">
                  Your Items <span>({cart.length} ITEMS)</span>
                </h6>
              </div>
              <ul className="space-y-4">
                {cart.map((item) => (
                  <li
                    key={item.id}
                    className="flex min-h-[200px] items-start justify-between border-b pb-4"
                  >
                    <div className="w-32 h-28">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <div className="w-full flex flex-1 py-3">
                        <p className="font-medium text-sm uppercase">
                          {item?.itemData?.name}{" "}
                          <span className="text-xs font-normal capitalize">
                            (
                            {
                              item?.itemData?.variations[0]?.itemVariationData
                                ?.name
                            }
                            )
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 px-8">
                      <h3 className="font-semibold text-lg">{item.name}</h3>
                      <p className="text-sm">
                        $
                        {
                          item?.itemData?.variations[0]?.itemVariationData
                            ?.priceMoney?.amount
                        }{" "}
                        <span className="ml-2 text-gray-500">
                          x {item.quantity}
                        </span>
                      </p>
                      <ul className="mt-2 text-sm">
                        {/* <li>
                          <strong>Toasting:</strong> <br />{" "}
                          {item.options.toasting}
                        </li> */}
                        {/* <li>
                          <strong>Spreads:</strong> <br />{" "}
                          {item.options.spreads}
                        </li> */}
                        {/* <li>
                          <strong>Add-ons:</strong> <br /> {item.options.addons}
                        </li> */}
                      </ul>
                      <div className="flex items-center mt-4 space-x-3">
                        <button
                          onClick={() => handleDecrement(item.id)}
                          className="w-7 h-7 bg-[#eab43e] rounded-full flex items-center justify-center text-white hover:bg-[#d9a636] transition-colors text-lg font-medium"
                        >
                          -
                        </button>
                        <span className="w-8 text-center text-gray-700 font-medium">
                          {item.quantity}
                        </span>
                        <button
                          onClick={() => handleIncrement(item.id)}
                          className="w-7 h-7 bg-[#eab43e] rounded-full flex items-center justify-center text-white hover:bg-[#d9a636] transition-colors text-lg font-medium"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="font-semibold text-lg">
                        $
                        {item?.itemData?.variations[0]?.itemVariationData
                          ?.priceMoney?.amount * item?.quantity}
                      </p>
                      <button
                        onClick={() => handleDeleteItem(item.id)}
                        className="text-yellow-500 mt-2"
                      >
                        <FaRegTrashAlt />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-4">
                <Link
                  to={"/menu"}
                  className="block h-[60px] text-center bg-[#eab43e] text-white py-5 rounded hover:[#eab43e]"
                >
                  {cart.length > 0 ? (
                    <p className="font-normal">Add more items</p>
                  ) : (
                    <p>Add items</p>
                  )}
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="border rounded-lg shadow-md p-4 bg-[#666666]">
              <div className="border-b pb-2 mb-4">
                <h6 className="text-lg text-white font-semibold">
                  Cart Summary
                </h6>
              </div>
              <ul className="space-y-4 text-sm text-white">
                <li className="flex justify-between">
                  Subtotal{" "}
                  <span>
                    $
                    {cart.reduce(
                      (total, item) =>
                        total +
                        item.itemData?.variations[0]?.itemVariationData
                          ?.priceMoney?.amount *
                          item.quantity,
                      0
                    )}
                  </span>
                </li>
                <li className="flex justify-between">
                  Estimated taxes (GA, US) <span>$5</span>
                </li>
                <li className="flex justify-between">
                  Estimated order total
                  <span>
                    $
                    {cart.reduce(
                      (total, item) =>
                        total +
                        item.itemData?.variations[0]?.itemVariationData
                          ?.priceMoney?.amount *
                          item.quantity,
                      0
                    ) + 5}
                  </span>
                </li>
                <li>
                  <small className="text-gray-500">
                    Additional taxes and fees will be calculated at checkout
                  </small>
                </li>
              </ul>
              {cart.length > 0 && (
                <div className="mt-4">
                  <Link
                    to="/checkout"
                    className="block h-[60px] text-center bg-[#eab43e] text-white py-4 rounded hover:bg-[#eab43e]"
                  >
                    Continue to checkout
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cart;
