import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="w-full mt-3 bg-gray-200 py-10">
      <div className="container mx-auto px-5">
    
        <div className="flex flex-col md:flex-row md:justify-between gap-8">
       
          <div className="flex justify-center md:justify-start">
            <img
              className="w-[120px] h-auto object-contain"
              src="/images/ftr-logo.png"
              alt="Footer Logo"
            />
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 text-center md:text-left text-black">
            <ul>
              <li className="mb-2 cursor-pointer hover:text-gray-500">Home</li>
              <li className="cursor-pointer hover:text-gray-500">Category</li>
            </ul>
            <ul>
              <li className="mb-2 cursor-pointer hover:text-gray-500">Menu</li>
              <li className="cursor-pointer hover:text-gray-500">Location</li>
            </ul>
            <ul>
              <li className="mb-2 cursor-pointer hover:text-gray-500">Contact</li>
              <li className="cursor-pointer hover:text-gray-500">Get Franchise</li>
            </ul>
            <ul>
              <li className="mb-2 font-bold">Address</li>
              <li className="text-sm">
                1810 Peachtree Pkwy Suite 108 <br /> Cumming, GA 30041
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-10 flex flex-col md:flex-row md:justify-between items-center text-black">

          <p className="text-center md:text-left mb-5 md:mb-0 text-sm">
            © 2024 The Bagel Hole. All Rights Reserved.
          </p>

 
          <div className="flex items-center gap-4">
            <small>Follow us on:</small>
            <Link
              to="#"
              className="flex items-center justify-center w-10 h-10 bg-yellow-500 text-black rounded-lg hover:bg-yellow-600 transition"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link
              to="#"
              className="flex items-center justify-center w-10 h-10 bg-yellow-500 text-black rounded-lg hover:bg-yellow-600 transition"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
