import { ADD_TO_CART, CLEAR_CART, DECREASE_QUANTITY, INCREASE_QUANTITY, REMOVE_FROM_CART } from "./action";

const initialState = {
    cart: [],
    error: null,
    isPending: false,
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART: {
            const existingProduct = state.cart.find(
                (item) => item.id === action.payload.id
            );

            if (existingProduct) {
                // Update quantitty if prodct already exists
                const updatedCart = state.cart.map((item) =>
                    item.id === action.payload.id
                        ? { ...item, quantity: item.quantity + 1 }
                        : item
                );
                return {
                    ...state,
                    cart: updatedCart,
                };
            }

            return {
                ...state,
                cart: [...state.cart, { ...action.payload, quantity: 1 }],
            };
        }

        case INCREASE_QUANTITY: {
            const updatedCart = state.cart.map((item) =>
                item.id === action.payload.id
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            );
            return {
                ...state,
                cart: updatedCart,
            };
        }

        case DECREASE_QUANTITY: {
            const updatedCart = state.cart.map((item) =>
                item.id === action.payload.id
                    ? { ...item, quantity: Math.max(item.quantity - 1, 1) }
                    : item
            );
            return {
                ...state,
                cart: updatedCart,
            };
        }

        case REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter((item) => item.id !== action.payload.id),
            };
        case CLEAR_CART:
            return { ...state, cart: [] }

        default:
            return state;
    }
};


export default cartReducer;
