import { InputNumber } from "antd";
import React from "react";
import ReviewsCart from "../component/ReviewsCart";

const Category = () => {
  return (
    <div className="mt-16 w-full px-4 md:px-16">
      <section className="grid md:grid-cols-2 grid-cols-1 gap-10">
        <div className="max-w-[500px] h-[300px] bg-center rounded-3xl overflow-hidden ">
          <img
            src="/images/blueberry.jpg"
            alt="product"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col justify-between items-start text-zinc-600">
          <div className="space-y-3">
            <h3 className="capitalize text-2xl font-semibold">
              blue berry bagel
            </h3>
            <p className="text-xl font-semibold">$2.20</p>
          </div>
          <p className="font-normal py-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia a
            veniam repudiandae vero id excepturi unde illo dignissimos fugit vel
            exercitationem tempore explicabo repellat, dicta amet rem earum ut.
            Tenetur.
          </p>
          <div className=" flex flex-row md:items-start items-center md:justify-start justify-between w-full md:flex-col gap-4">
            <InputNumber
              min={1}
              max={12}
              defaultValue={1}
              onChange={(value) => console.log(value)}
              controls={true}
              style={{ width: "120px" }}
              size="large"
            />
            <button
              key="addToCart"
              //   onClick={handleSubmit(onSubmit)}
              className="bg-yellow-500 text-white w-36 px-6 py-2 rounded-full hover:bg-yellow-600 transition"
            >
              Order Now
            </button>
          </div>
        </div>
      </section>
      <section>
        <h1 className="text-4xl font-semibold text-[#bfbfbf] mt-16">Reviews</h1>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mt-10">
          <ReviewsCart />
          <ReviewsCart />
          <ReviewsCart />
          <ReviewsCart />
          <ReviewsCart />
          <ReviewsCart />
        </div>
      </section>
    </div>
  );
};

export default Category;
