import React from "react";
import AddCategory from "./components/AddCategory";
import AddOns from "./components/AddOns";
import AddMenuItem from "./components/AddMenuItem";
import CustomTabs from "../../component/CustomTabs";

const AddProduct = () => {
  const tabData = [
    {
      label: "Add Category",
      content: <AddCategory />,
    },
    // {
    //   label: "Add-Ons",
    //   content: <AddOns />,
    // },
    {
      label: "Add Menu Item",
      content: <AddMenuItem />,
    },
  ];

  return (
    <div className="mt-2 ">
      <div className="mt-10">
        <CustomTabs tabData={tabData} />
      </div>
    </div>
  );
};

export default AddProduct;
