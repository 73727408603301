import React, { useState } from "react";
import {
  MdOutlineNotifications,
  MdOutlinePowerSettingsNew,
} from "react-icons/md";

import { VscKey } from "react-icons/vsc";
import { RxAvatar } from "react-icons/rx";
import { useNavigate, useParams } from "react-router-dom";

import Customers from "./Customers";
import Sales from "./Sales";
import Menu from "./Menu";
import Complaints from "./Complaints";
import DashboardHome from "./DashboardHome";
import Orders from "./orders/Orders";
import SidebarCustom from "../component/Sidebar";
import CategoryDetails from "../pages/CategoryDetails";

const Main = () => {
  const navigate = useNavigate();
  const params = useParams();
  let { subpage } = useParams();

  console.log("Subpage Main==>", params.id);

  const pageName = subpage?.replace("-", " ");

  return (
    <div className="nunito-sans flex bg-[#EDEDE9] p-2">
      <SidebarCustom subpage={subpage} className="flex-shrink-0 bg-gray-800" />
      <div className="min-h-screen w-screen md:px-10 px-4">
        <header className="flex h-auto w-full items-center justify-between ">
          {/* <h1 className="text-2xl font-semibold capitalize">{pageName}</h1> */}
          {/* <div className="flex items-center justify-between rounded-2xl bg-white w-full p-6 text-stone-50">
            <h1 className="text-lg   capitalize">{pageName}</h1>

            <div className="flex items-center justify-between gap-4">
              <MdOutlineNotifications size={22} className="cursor-pointer" />
              <p className="font-semibold">Username</p>

              <RxAvatar size={30} className="cursor-pointer" />
            </div>
          </div> */}
        </header>
        {subpage === "main" && <DashboardHome />}
        {subpage === "orders" && <Orders  />}
        {subpage === "customers" && <Customers />}
        {subpage === "menu" && <Menu />}
        {subpage === "complaints" && <Complaints />}
        {subpage === "sales-report" && <Sales />}
        {subpage ==="category-details" && <CategoryDetails />}
      </div>
    </div>
  );
};

export default Main;
