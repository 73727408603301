import React, { useState } from "react";
import { currentData, orderStatusData } from "../utils";
import TableComponent from "../component/Table";
import ReviewBar from "../component/ReviewBar";
import EntriesDropdown from "../component/DropDown";

const DashboardHome = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <div className="mt-2">
      <div>
        <h1 className="text-[34px] font-bold border-b-4 border-primary">Dashboard</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-8">
        <div>
          <h3 className="py-2 px-2 text-lg font-semibold border-b-2 border-primary w-[30%]">Order Status</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-6">
            {orderStatusData.map((card, index) => (
              <div
                key={index}
                className="bg-[#E5E5E2] rounded-3xl px-10 h-40 flex flex-col justify-center gap-6"
              >
                <p className="font-[400] capitalize">{card.title}</p>
                <p className="text-xl font-bold">{card.numbers}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-14">
          {/* <h3 className="py-4 px-2 text-lg font-semibold">Sales Report</h3>
          <div className="bg-[#E5E5E2] rounded-3xl px-10 flex flex-col w-full min-h-[340px] justify-around gap-6">
            <div className="flex justify-between items-center">
              <p className="text-lg font-[400]">Pending Orders</p>
              <p className="text-xl font-bold">1520</p>
            </div>
            <div className="flex justify-center">
              <p>Graph</p>
            </div>
            <div className="flex justify-center">
              <p>Sales Status Report</p>
            </div>
          </div> */}
          <ReviewBar />
        </div>
      </div>

      {/* <div className="my-10">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
          <div>
            <h3 className="py-4 px-2 text-lg font-semibold">Order Status</h3>

           
            <div className="container mx-auto min-w-full p-4">
              <div className="h-[450px] overflow-x-auto overflow-y-auto rounded-xl">
                <table className="min-w-full divide-y divide-gray-200 rounded-xl bg-[#E5E5E2]">
                  <thead className="sticky top-0 m-0 rounded-xl  text-[#001219]/50">
                    <tr className="rounded-xl">
                      <th>ID#</th>
                      <th>Customer</th>
                      <th>Item</th>
                      <th>Phone</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#001219]/50 bg-transparent">
                    {currentData.map((row) => (
                      <tr key={row.id}>
                        <td className="pr-4">{row.id}</td>
                        <td>{row.customer}</td>
                        <td>{row.item}</td>
                        <td>{row.phone}</td>
                        <td>{row.amount}</td>
                        <td>{row.status}</td>
                        <td>{row.action}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div>
            <h3 className="py-4 px-2 text-lg font-semibold">Order Status</h3>

            <div className=" bg-[#E5E5E2] rounded-3xl p-4">Right</div>
          </div>
        </div>
      </div> */}
      <div className="mt-14 ">
        <h1 className="border-b-2 border-b-primary w-[20%] text-[24px]">
          Recent Order Status
        </h1>
        <TableComponent />
        
      </div>
    </div>
  );
};

export default DashboardHome;
