import { useQuery } from "@tanstack/react-query"
import { GET_ALL_CUSTOMER } from "./customer-type"
import axios from "axios"


const apiUrl = process.env.REACT_APP_BASE_URL || "http://localhost:5000";
export const useALLCustomer = ()=> {
    return useQuery({
        queryKey: [GET_ALL_CUSTOMER],
        queryFn: ()=> axios.get(`${apiUrl}/api/customers`)
    })
};