import React from "react";
import PendingOrder from "./components/PendingOrder";
import CustomTabs from "../../component/CustomTabs";
import CanceledOrder from "./components/CanceledOrder";
import CompletedOrder from "./components/CompletedOrder";

const Orders = () => {
  const tabData = [
    {
      label: "Pending Orders",
      content: <PendingOrder />,
    },
    {
      label: "Canceled Orders",
      content: <CanceledOrder />,
    },
    {
      label: "Completed Orders",
      content: <CompletedOrder />,
    },
  ];

  return (
    <div className="mt-2 ">
      <h1 className="text-[34px] border-b-4 border-primary p-2">Orders</h1>
      <div className="mt-10">
        <CustomTabs tabData={tabData} />
      </div>
    </div>
  );
};

export default Orders;
