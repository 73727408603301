import React from "react";

const Schmear = () => {
  return (
    <div className="mt-16 sm:px-12 px-4 w-full max-w-[1700px] mx-auto">
      Schmear
    </div>
  );
};

export default Schmear;
