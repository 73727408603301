import React, { useState } from "react";

const CustomTabs = ({ tabData = [] }) => {
  
  const [activeTab, setActiveTab] = useState(tabData.length > 0 ? tabData[0].label : null);

  if (tabData.length === 0) {
    return <div>No tabs available</div>; 
  }

  return (
    <div>
      <div className="flex gap-6">
        {tabData.map((tab, index) => (
          <button
            key={index}
            className={` font-semibold transition-colors duration-300 focus:outline-none ${
              activeTab === tab.label ? "border-b-2 border-black" : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="mt-4">
        {tabData.map(
          (tab, index) =>
            activeTab === tab.label && (
              <div key={index} className="tab-content">
                {tab.content}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default CustomTabs;
