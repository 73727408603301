import React from "react";

const Sandwich = () => {
  return (
    <div className="mt-16 sm:px-12 px-4 w-full max-w-[1700px] mx-auto">
      Sandwich
    </div>
  );
};

export default Sandwich;
