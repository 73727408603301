import React, { useEffect, useRef, useState } from "react";
import { FaBars, FaRegTrashAlt, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseQuantity,
  increaseQuantity,
  removeFromCart,
} from "../features/cart/action";
import ItemCart from "./ItemCart";

const Navbar = () => {
  const [isShowCard, setIsShowCard] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowCard(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  //handle increase item
  const handleIncrement = (id) => {
    dispatch(increaseQuantity(id));
  };

  //handle increase item
  const handleDecrement = (id) => {
    dispatch(decreaseQuantity(id));
  };

  //handle delete items
  const handleDeleteItem = (id) => {
    dispatch(removeFromCart(id));
  };

  const handleRoute = () => {
    setIsShowCard(false);
    navigate("/cart");
  };

  const isUserAuth = useSelector((state) => state.auth.isUserAuth);
  console.log("isUserAuth", isUserAuth);

  return (
    <div className="h-[110px] bg-[#7E7E7E] flex justify-center items-center">
      <div className="w-[90%] h-[90%] flex justify-between items-center">
        <div className="w-[180px] h-[80px]">
          <Link to="https://aamgstaging.com/thebagelhole/">
            <img src="/logo.svg" alt="Logo" />
          </Link>
        </div>

        <div
          className={` fixed top-0 right-0 w-[70%] h-full bg-[#333] text-white flex flex-col justify-center items-center gap-5 transition-transform transform ${
            isMenuOpen ? "translate-x-0" : "translate-x-full"
          } md:static md:translate-x-0 md:flex md:flex-row md:gap-4 md:bg-transparent`}
        >
          <ul className="flex flex-col md:flex-row gap-5 text-white text-lg ">
            {/* <li className="cursor-pointer hover:text-zinc-300 hover:text-[#eab43e]">
              {" "}
              <Link to={"/"}>Home </Link>
            </li>
            <li className="cursor-pointer hover:text-zinc-300 hover:text-[#eab43e]">
              <Link to={"/category"}>Category</Link>
            </li> */}
            {/* <li className="cursor-pointer hover:text-zinc-300 hover:text-[#eab43e]">
              <Link to={"/menu"}>Menu</Link>
            </li> */}
            <h1 className="text-5xl font font-bold tracking-wider">ORDER ONLINE NOW !</h1>
            {/* {!isUserAuth ? (
              <li className="cursor-pointer hover:text-zinc-300 hover:text-[#eab43e]">
                <Link to={"/signin"}>Login</Link>
              </li>
            ) : (
              <></>
            )} */}
          </ul>
          <button
            className="absolute top-4 right-4 text-white text-2xl md:hidden"
            onClick={toggleMenu}
          >
            <FaTimes />
          </button>
        </div>
        <div className="text-white text-2xl md:hidden" onClick={toggleMenu}>
          <FaBars />
        </div>

        {/* <div className="hidden md:flex gap-5 h-[32px] ">
          <Link to={"/cart"} className="relative">
            {cart.length > 0 && (
              <span className="absolute bottom-4 left-5 w-6 h-6 flex items-center justify-center text-sm font-bold text-white bg-yellow-500 rounded-full">
                {cart.length}
              </span>
            )}
            <img
              className="h-[100%] w-[100%]"
              src="/images/shopping_bag_32dp_WHITE_FILL0_wght400_GRAD0_opsz40.png"
              alt="Cart"
            />
          </Link>
         
        </div> */}
        <ItemCart color={"white"} />
      </div>
    </div>
  );
};

export default Navbar;
