import { combineReducers, configureStore } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
import { setupAxiosInterceptors } from "../api/axiosInstance";
import authReducer from "../features/auth/authSlice";
import cartReducer from "../features/cart/cartReducer";


const authPersistConfig = {
  key: "auth",
  storage,
};

const cartPersistConfig = {
  key: "cart",
  storage,
};



const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  cart: persistReducer(cartPersistConfig, cartReducer),


});

const store = configureStore({
  reducer: rootReducer,
});

setupAxiosInterceptors(store);

const persistor = persistStore(store);

export { store, persistor };
