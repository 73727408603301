

import React, { useRef, useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { categories } from "../utils";
import ItemCart from "./ItemCart";

const MenuNav = ({
  sectionRefs,
  navRef,
  category,
  navPosition,
  setNavPosition,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showFloatingCart, setShowFloatingCart] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const sentinelRef = useRef(null); 
  const [isStuck, setIsStuck] = useState(false); 

  useEffect(() => {
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsStuck(!entry.isIntersecting);
      },
      { rootMargin: "0px 0px 0px 0px" }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) observer.unobserve(sentinelRef.current);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {

      sectionRefs?.current.forEach((section, index) => {
        const rect = section?.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom >= 100) {
          setActiveIndex(index);
        }
      });

    
      setShowFloatingCart(window.scrollY > 110); 

      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [sectionRefs]);

  const handleCategoryClick = (index) => {
    sectionRefs?.current[index].scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setActiveIndex(index);
  };

  const handleScrollLeft = () => {
    navRef?.current.scrollBy({
      left: -200,
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    navRef?.current.scrollBy({
      left: 200,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {/* Sentinel for sticky detection */}
      <div ref={sentinelRef} className="h-[1px]"></div>

      {/* Only show floating cart when scrolled past navbar */}
      {showFloatingCart && (
        <div
          className={`fixed right-8 transition-all duration-300 z-50 ${
            isScrolled ? "top-24" : "top-8"
          }`}
        >
          <div className="bg-white rounded-full shadow-lg p-2 hover:shadow-xl transition-shadow">
            <ItemCart color={"black"} />
          </div>
        </div>
      )}

      {/* Navbar */}
      <div
  className={`w-full  bg-white z-30 transition-all duration-200 ${
    isStuck
      ? "fixed top-0 left-0 shadow-lg "
      : "relative "
  }`}
>

        <div className="flex items-center justify-between space-x-2 p-4">
          <button
            onClick={handleScrollLeft}
            className="p-2 text-gray-500 hover:text-black"
          >
            <FaChevronLeft />
          </button>

          <div
            ref={navRef}
            className="flex space-x-4 overflow-x-auto no-scrollbar"
          >
            {category?.categories?.map((category, index) => (
              <button
                key={index}
                onClick={() => handleCategoryClick(index)}
                className={`flex-shrink-0 p-4 rounded-full transition-all duration-200 ${
                  activeIndex === index
                    ? "bg-yellow-500 text-white"
                    : "bg-[#bfbfbf]/50 text-gray-700"
                }`}
              >
                {category.categoryData.name}
              </button>
            ))}
          </div>

          <button
            onClick={handleScrollRight}
            className="p-2 text-gray-500 hover:text-black"
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuNav;
