import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

import { GET_ALL_ORDERS } from "./orders-types";
import { fetchAll, deleteData } from "../../Apis/Apis";
import { toast } from 'react-hot-toast';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_BASE_URL;

export const useAllOrders = () => {
    return useQuery({
        queryKey: [GET_ALL_ORDERS],
        queryFn: () => fetchAll('/api/orders')
    });
};

export const useCancelOrder = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id) => axios.delete(`${apiUrl}/api/order/${id}`),
        onSuccess: (data) => {
            toast.success('Order canceled successfully');
            queryClient.invalidateQueries({ queryKey: [GET_ALL_ORDERS] });
        },
        onError: (error) => {
            toast.error(error.message || 'Failed to cancel order');
        }
    });
};