import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { register as signup } from "../features/auth/authService";
import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from "../features/auth/actionTypes"; // Adjust this path according to your project structure
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SignUp = () => {
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { isUserAuth, isAdminAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isUserAuth) {
      navigate("/");
    } else if (isAdminAuth) {
      navigate("/admin/watchlist");
    }
  }, [isUserAuth, isAdminAuth, navigate]);

  const schema = yup
    .object({
      name: yup.string().required("Name is required"),
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character",
        )
        .required("Password is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { name, email, password } = data;
    setisLoading(true);
    const signupData = { name, email, password };

    try {
      const response = await signup(signupData);
      const data = response.data;

      dispatch({ type: REGISTER_SUCCESS, payload: data });
     
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      dispatch({ type: REGISTER_FAILURE, payload: error.response.data });
     
    }
  };

  if (isAdminAuth || isUserAuth) return;
  return (
    <div className="flex h-full w-full items-center justify-center">
      <img
        src="/images/yellowbg-content.png"
        alt="background"
        className="absolute inset-0 w-full h-full object-cover z-[-10]"
      />
      <div className="grid w-full grid-cols-1 items-center justify-between md:grid-cols-2">
        <div className="hidden items-center justify-center md:flex">
          <img src="/images/logo.svg" alt="logo" width={600} />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex min-h-screen w-full flex-col items-center justify-center gap-6"
        >
          <img
            src="/images/logo.svg"
            alt="logo"
            className="flex md:hidden"
            width={300}
          />

          <h1 className="text-4xl text-center text-black uppercase font-bold">
            Knead it, eat it <br />
            love it!!!
          </h1>
          <p className="space-y-5 text-black font-semibold text-center">
            Come nosh on a bigger, better New <br /> York style bagel
          </p>
          <div className="flex w-full flex-col items-center justify-center gap-6 px-4 sm:px-0">
          <div className="w-[50%]">
              <input
                type="text"
                placeholder="Enter name"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setUserEmail(e.target.value)}
                disabled={isLoading}
                {...register("name")}
              />

              {errors?.name && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.name?.message}
                </p>
              )}
            </div>
            <div className="w-[50%] ">
              <input
                type="email"
                placeholder="Enter email"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setUserEmail(e.target.value)}
                disabled={isLoading}
                {...register("email")}
              />

              {errors?.email && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.email?.message}
                </p>
              )}
            </div>
            <div className="w-[50%]">
              <input
                type="password"
                placeholder="Enter password"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
                {...register("password")}
              />
              {errors?.password && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.password?.message}
                </p>
              )}
            </div>
            <button className="w-1/2 rounded-lg bg-black p-3 text-white">
              SignUp
            </button>
          </div>
          <p className="text-center text-sm text-gray-200">
            OR <br />
            <br />
            If you already have an account, click to{" "}
            <Link
              to="/signin"
              className="cursor-pointer font-semibold uppercase underline"
            >
              Signin
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
